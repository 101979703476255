<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
    <v-form ref="form" @submit.prevent="salvarChamado">
      <v-stepper
        v-model="step"
        vertical
        non-linear
      >
        <v-stepper-step
          editable
          :complete="step > 1"
          step="1"
          :rules="[() => this.step == 1 || !!this.item.praca]"
        >
          Qual é a praça?
          <small v-if="item.praca">
            {{item.praca.descricao}}
          </small>
          <small v-else>Selecione a praça que necessita de atendimento</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card
            class="mb-12"
            elevation="0"
          >
            <v-autocomplete
              clearable
              :items="pracas.data"
              :loading="pracas.loading"
              label="Praça"
              item-text="descricao"
              name="praca"
              autocomplete="off"
              v-model="item.praca"
              @change="changePraca()"
              return-object
              :rules="[rules.required]"
            ></v-autocomplete>
          </v-card>
          <v-btn
          :disabled="!item.praca"
            color="primary"
            @click="nextStep()"
          >
            Próximo
          </v-btn>
          <v-btn text @click="fecharForm">
            Cancelar
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          editable
          :complete="step > 2"
          step="2"
        >
          Vincular pista?
          <small v-if="item.pista">
            {{item.pista.descricao}}
          </small>
          <small v-else>Selecione uma pista (opcional)</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card
            class="mb-12"
            elevation="0"
          >
            <v-autocomplete
              clearable
              :items="pistas.data"
              :loading="pistas.loading"
              label="Pista"
              item-text="descricao"
              name="pista"
              autocomplete="off"
              no-data-text="Selecione uma praça"
              v-model="item.pista"
              @change="changePista()"
              return-object
            ></v-autocomplete>
          </v-card>
          <v-btn
            color="primary"
            @click="nextStep()"
          >
            Próximo
          </v-btn>
          <v-btn text @click="fecharForm">
            Cancelar
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          editable
          :complete="step > 3"
          step="3"
        >
          Vincular ativo?
          <small v-if="item.ativo">
            {{item.ativo.descricao}}
          </small>
          <small v-else>Selecione um ativo (opcional)</small>
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card
            class="mb-12"
            elevation="0"
          >
            <v-autocomplete
              clearable
              :items="ativos.data"
              :loading="ativos.loading"
              label="Ativo"
              item-text="descricao"
              name="ativo"
              autocomplete="off"
              no-data-text="Selecione uma pista"
              v-model="item.ativo"
              return-object
            ></v-autocomplete>
          </v-card>
          <v-btn
            color="primary"
            @click="step = 4"
          >
            Próximo
          </v-btn>
          <v-btn text @click="fecharForm">
            Cancelar
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          editable
          step="4"
        >
          Descreva o ocorrido
          <small>Descreva detalhadamente o ocorrido</small>
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card
            class="mb-12"
            elevation="0"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  clearable
                  label="Tipo de manutenção"
                  :items="tipos"
                  v-model="item.tipo"
                  item-text="descricao"
                  item-value="id"
                  hint="Não obrigatório"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  clearable
                  label="Prioridade"
                  :items="prioridades"
                  v-model="item.prioridade"
                  item-text="descricao"
                  item-value="id"
                  hint="Não obrigatório"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="item.relato"
                  label="Relato"
                  auto-grow
                  rows="3"
                  :rules="[rules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
          <v-btn
            color="primary"
            type="submit"
            :loading="formLoading"
          >
            Abrir chamado
          </v-btn>
          <v-btn text @click="fecharForm">
            Cancelar
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-form>
  </v-dialog>
</template>

<script>
import preChamadoService from '@/services/preChamadoService'
import pracaService from '@/services/pracaService'
import pistaService from '@/services/pistaService'
import ativoService from '@/services/ativoService'
import FormPadrao from './FormPadrao'

export default {
  extends: FormPadrao,
  watch: {
    showDialog() {
      this.step = "1"
    }
  },
  data() {
    return {
      service: preChamadoService,
      pracas: {
        loading: false,
        data: []
      },
      pistas: {
        loading: false,
        data: []
      },
      ativos: {
        loading: false,
        data: []
      },
      step: "1",
      idcliente: null,
      tipos: [
        { id: 'C', descricao: 'Corretiva' },
        { id: 'M', descricao: 'Melhoria' },
        { id: 'I', descricao: 'Instalação' }
      ],
      prioridades: [
        { id: 'baixa', descricao: 'Baixa' },
        { id: 'media', descricao: 'Média' },
        { id: 'alta', descricao: 'Alta' },
        { id: 'urgente', descricao: 'Urgente' }
      ]
    }
  },
  mounted() {
    let usuario    = localStorage.getItem('usuario')
    this.idcliente = JSON.parse(usuario).idcliente || null

    this.getPracas()
  },
  methods: {
    getPracas() {
      this.pracas = {
        loading: true,
        data: []
      }
      pracaService.getAll({idcliente: this.idcliente, ativos: true }).then((response) => {
        if (!response.erro) {
          this.pracas = {
            loading: false,
            data: response.data
          }
        }
      })
    },
    getPistas() {
      this.pistas = {
        loading: true,
        data: []
      }

      pistaService.getAll({idpraca: this.item.praca.id}).then((response) => {
        if (!response.erro) {
          this.pistas = {
            loading: false,
            data: response.data
          }

          this.pistas.data.forEach(val => {
            val.descricao = `Pista ${val.sentido.toUpperCase()} - ${val.numero}`
          })
        }
      })
    },
    getAtivos() {
      this.ativos = {
        loading: true,
        data: []
      }

      ativoService.getAll({idpista: this.item.pista.id}).then((response) => {
        if (!response.erro) {
          this.ativos = {
            loading: false,
            data: response.data
          }
        }
      })
    },
    changePraca() {
      this.item.ativo = this.item.pista = null
      if (!this.item.praca) {
        this.ativos = this.pistas = []
      }
    },
    changePista() {
      this.item.ativo = null
      if (!this.item.pista) {
        this.ativos = []
      }
    },
    nextStep() {
      switch (this.step) {
        case "1":
          if (this.item.praca) {
            this.getPistas()
          }
          this.step = "2"
          break
        case "2":
          if (this.item.pista) {
            this.getAtivos()
            this.step = "3"
          } else {
            this.step = "4"
          }
          break
      }
    },
    salvarChamado() {
      if (!this.$refs.form.validate()) {
        if (!this.item.praca) {
          this.step = "1"
        }
        return false
      }

      this.formLoading = true

      let data = {
        idcliente: this.item.idcliente,
        relato: this.item.relato,
        descricao: this.item.relato,
        tipo: this.item.tipo || "C",
        prioridade: this.item.prioridade
      }

      console.log(data)

      if (this.item.ativo) {
        data.entidade = "A"
        data.identidade = this.item.ativo.id
      } else if (this.item.pista) {
        data.entidade = "L"
        data.identidade = this.item.pista.id
      } else if (this.item.praca) {
        data.entidade = "P"
        data.identidade = this.item.praca.id
      }
      
      this.service
        .new(data)
        .then((response) => {
          if (!response.erro) {
            this.retornaItem(response.data)
          }
          this.formLoading = false
        })
        .catch((error) => {
          this.getErrorMessage(error)
          this.formLoading = false
        })
    }
  },
}
</script>