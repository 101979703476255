<template>
  <div class="main">
    <v-container class="container">
      <v-row class="my-4" justify="space-around">
        <h5 class="text-h5">O que deseja fazer hoje?</h5>
      </v-row>
      <v-row>
        <v-col
          v-for="botao in botoes"
          :key="botao.titulo"
          cols="12"
          sm="6"
        >
          <v-sheet
            class="buttonHome"
            color="primary"
            dark
            @click="$router.push(botao.destino)"
          >
            <div
              class="text-center py-7"
              v-text="botao.info"
            ></div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import menus from '@/menus.js'

  export default {
    name: 'Home',
    data() {
      return {
        usuario: {},
      }
    },
    mounted() {
      this.usuario = JSON.parse(localStorage.getItem('usuario'))
    },
    computed: {
      botoes() {
        var permissoes = this.$store.state.permissoes

        return menus.filter(e => permissoes.includes(e.can))
      }
    }
  }
</script>

<style>
  .buttonHome {
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }
</style>