import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '@/services/authService.js'
import store from '@/store'

import rotas from '@/routes.js'

Vue.use(VueRouter)
const routes = [
  ...rotas,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!['Login', 'RecuperarSenha'].includes(to.name) && authService.isAuthenticated() == false ) {
    let query = to.name ? '?pagina=' + to.name : "";
    next({ path: '/login' + query })
  } else if (to.name === 'Login' && authService.isAuthenticated()) {
    next({ name: 'Home' })
  } else if (!['Login', 'RecuperarSenha', 'Home'].includes(to.name) && (!store.state.permissoes.includes(to.name) && to.name !== true)) {
    next({ name: 'Home' })
  } else next()
})

export default router
