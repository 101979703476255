<template>
  <div class="fundo fill-height">
    <v-card width="600px">
      <v-card-title>
        <v-img height="80" contain src="@/assets/logo.png"></v-img>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-card-text>
              <v-form
                @submit.prevent="recuperarSenha"
                ref="formulario"
                id="form"
                lazy-validation
              >
                <p
                  class="text-center"
                >
                  Insira o código recebido por e-mail, e sua nova senha
                </p>
                <v-text-field
                  filled
                  label="E-mail do usuário"
                  prepend-icon="mdi-at"
                  required
                  v-model="email"
                  :rules="[requiredRule, emailRule]"
                />
                <v-text-field
                  filled
                  label="Código enviado no e-mail"
                  prepend-icon="mdi-asterisk"
                  required
                  v-model="codigo"
                  :rules="[requiredRule]"
                />
                <v-text-field
                  filled
                  label="Nova senha"
                  prepend-icon="mdi-lock"
                  required
                  v-model="senha_nova"
                  :rules="[requiredRule, minimumSizePassword]"
                  :hint="hintCaps"
                  v-on:keyup="checkCaps"
                  :type="show_new ? 'text' : 'password'"
                  :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_new = !show_new"
                />
                <v-text-field
                  filled
                  label="Confirmar senha"
                  prepend-icon="mdi-lock"
                  required
                  v-model="conf_senha_nova"
                  :rules="[checkPasswordRule]"
                  :hint="hintCaps"
                  v-on:keyup="checkCaps"
                  :type="show_new ? 'text' : 'password'"
                  :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_new = !show_new"
                />
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" justify="center">
            <v-btn type="submit" form="form" :loading="loadingBtn">
              <v-icon left>mdi-lock-check</v-icon>Confirmar senha
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import authService from "@/services/authService"

export default {
  name: "Login",
  data() {
    return {
      email: "",
      codigo: "",
      senha_nova: "",
      conf_senha_nova: "",
      requiredRule: (v) => !!v || "Campo é obrigatório",
      checkPasswordRule: (value) => !!value && value === this.senha_nova || 'Senhas não conferem.',
      minimumSizePassword: (value) => !!value && value.length >= 5 || 'A senha deve conter no mínimo 5 caracteres.',
      emailRule: (v) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'E-mail inválido.'
      },
      hintCaps: "",
      loadingBtn: false,
      show_new: false
    }
  },

  methods: {
    recuperarSenha() {
      if (!this.$refs.formulario.validate()) {
        return false
      }

      this.loadingBtn = true;

      let dados = {
        email: this.email,
        senha: this.senha_nova,
        conf_senha: this.conf_senha_nova,
        codigo: this.codigo
      }

      authService
        .confirmarRecuperacaoSenha(dados)
        .then((response) => {
          if (response) {
            this.$store.commit('snackMessage', {
              message: 'Senha recuperada com sucesso',
              color: 'success',
              closeButton: true,
              closeMessage: 'Obrigado'
            })
            this.$router.push({name: 'Login'})
          }
        }).catch((error) => {
          console.log(error);
          this.$store.commit('snackMessage', {
            message: 'Não foi possível concluir sua solicitação!',
            closeButton: true,
            closeMessage: 'Ok'
          })
        }).finally(() => {
          this.loadingBtn = false
        })
    },
    checkCaps(event) {
      this.hintCaps = event.getModifierState('CapsLock') ? 'Caps Lock ativo' : '';
    },
  },

  mounted() {
    this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
    localStorage.clear();
  },
};
</script>

<style scoped>
.fundo {
  display: inline;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4d4946;
}
</style>