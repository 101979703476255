<template>
  <v-container>
    <v-skeleton-loader
      v-if="loading"
      type="table"
    ></v-skeleton-loader>

    <v-data-table
      v-else
      :headers="headers"
      :items="pracas"
      :search="search"
      :loading="openingForm"
      sort-by="descricao"
      class="elevation-1"
      :expanded.sync="expanded"
      item-key="idpraca"
      single-expand
      show-expand
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Chamados</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
              >
                Período
                <v-icon
                  right
                  dark
                >
                  mdi-calendar-search
                </v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dates = []"
              >
                Limpar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="filtrarDatas(dates)"
              >
                Filtrar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-simple-table style="border-radius: 0; background: #F9F9F9">
            <template v-slot:default>
              <tbody>
                <v-hover 
                  v-for="(pista, i) in item.itens"
                  :key="`${item.idpraca}${i}`"
                  v-slot="{ hover }"
                >
                  <tr
                    @click="openForm(formChamado, pista)"
                    style="cursor: pointer"
                  >
                    <td class="text-start" style="width: 50%; padding-left: 30px">{{ pista.descricao }}</td>
                    <td class="text-end" style="width: 15%">{{ pista.melhorias }}</td>
                    <td class="text-end" style="width: 15%">{{ pista.corretivas }}</td>
                    <td class="text-end" style="width: 15%">{{ pista.preventivas }}</td>
                    <td class="text-start" style="width: 5%">
                      <v-tooltip v-if="hover" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            dense
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-timeline-text
                          </v-icon>
                        </template>
                        <span>Ver chamados</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </v-hover>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <v-overlay :value="openingForm"></v-overlay>

    <form-timeline-chamado
      formWidth="900px"
      :showDialog="formChamado.show"
      :formItem="formChamado.item"
      @formClose="closeForm(formChamado)"
    ></form-timeline-chamado>
  </v-container>
</template>

<script>
  import FormTimelineChamado from '@/components/FormTimelineChamado'
  import chamadoService from '@/services/chamadoService'
  //import moment from 'moment'

  export default {
    data() {
      return {
        pracas: [],
        loading: false,
        idcliente: null,
        search: '',
        dates: [],
        menu: false,
        expanded: [],
        openingForm: false,
        headers: [
          { text: 'Praça', value: 'praca', align: 'start', width: '50%' },
          { text: 'Melhorias', value: 'melhorias', align: 'end', width: '15%' },
          { text: 'Corretivos', value: 'corretivas', align: 'end', width: '15%' },
          { text: 'Preventivos', value: 'preventivas', align: 'end', width: '15%' },
          { text: '', value: 'data-table-expand', width: '5%' }
        ],
        formChamado: {
          show: false,
          item: {}
        }
      }
    },
    components: {
      FormTimelineChamado
    },
    mounted() {
      let usuario    = localStorage.getItem('usuario')
      this.idcliente = JSON.parse(usuario).idcliente || null
      
      this.getChamados()
    },
    methods: {
      filtrarDatas(dates) {
        this.$refs.menu.save(dates)
        this.menu = false
        
        this.getChamados()
      },
      getChamados() {
        this.loading = true
        let params = {
          idcliente: this.idcliente
        }

        if (this.dates[0] != undefined && this.dates[0]) {
          params.dataini = this.dates[0];
        }
        if (this.dates[1] != undefined && this.dates[1]) {
          params.datafim = this.dates[1];
        }

        chamadoService.getAllResumo(params).then((response) => {
          if (!response.erro) {
            this.pracas = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })
      },
      openForm(form, item) {
        this.openingForm = true

        let chamados = []
        let filtro = {
          dinamico: true,
          with: [
            'interacoes:id,interacao,idchamado,iduser',
            'interacoes.imagem:entidade,chave,path',
            'interacoes.usuario:id,nome,foto_perfil'
          ]
        }
        
        if (item.idativo) {
          filtro['filtros[0]'] = ['idativo', '=', item.idativo]
        } else if (item.idpista) {
          filtro['filtros[0]'] = ['idpista', '=', item.idpista]
        } else if (item.idpraca) {
          filtro['filtros[0]'] = ['idpraca', '=', item.idpraca]
        } else {
          this.$store.commit('snackMessage', {
            message: 'Erro ao processar a solicitação. Por favor, tente novamente',
            color: 'error',
            closeButton: true,
            closeMessage: 'Ok'
          })

          return false
        }

        chamadoService.getAll(filtro).then((response) => {
          if (!response.erro) {
            chamados = response.data

            form.item = {
              'chamados': chamados.reverse(),
              'descricao': item.descricao
            }
            form.show = true
          }
        }).catch((error) => {
          this.getErrorMessage(error)
        }).finally(() => {
          this.openingForm = false
        })
      },
      closeForm(form) {
        form.show = false,
        form.item = {}
      },
      getErrorMessage(error) {
        const errorData = error.response && error.response.data
        const errorMessage =
          (errorData && errorData.mensagem) ||
          'Não foi possivel completar a operação'
        const errorFields = (error.response && errorData.erros) || {}
        const fieldsMessage = Object.values(errorFields).join('\n')

        const finalMessage = fieldsMessage
          ? errorMessage + '\n' + fieldsMessage
          : errorMessage

        //alert(finalMessage)
        this.$store.commit('snackMessage', {
          message: finalMessage,
          color: 'error',
          closeButton: true,
          closeMessage: 'Ok'
        })
      }
    }
  }
</script>