import axios from "axios";
import cookies from "vue-cookies";

let config = {
  baseURL: process.env.VUE_APP_API,
  withCredentials: false, 
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    
    if (config.url.search('login') == -1) {
      config.headers['Authorization'] = 'Bearer ' + cookies.get('token')
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      location.replace("/login?path=" + encodeURIComponent(location.pathname));
      /*error = {
        error: 'Não autorizado'
      }*/
    }
    return Promise.reject(error);
  }
);

export default _axios;
