<template>
  <v-snackbar
    v-model="snackbar.show"
    v-bind="snackbar"
  >
    {{ snackbar.message }}

    <template v-if="snackbar.closeButton" v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar.show = false"
      >
        {{ snackbar.closeMessage }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'SnackBar',
  computed:{
    snackbar:{
      get() {
        return this.$store.state.snackbar
      },
      set() {
        //this.$store.commit('changeDrawer', bool)
      }
    },
  },
  data() {
    return {
    }
  }
}
</script>
