import crudService from "@/services/crudService";

class preChamadoService extends crudService {

  constructor() {
    super();
    this.endpoint = '/prechamado';
  }
}

export default new preChamadoService;