import Vue from 'vue';
import Vuetify from 'vuetify';
import pt from 'vuetify/src/locale/pt.ts'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#d90b16',
        secondary: '#999796',
        anchor: '#8c9eff',
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  }
});
