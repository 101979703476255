<template>
  <v-card class="mx-2">
    <v-card-title class="text-subtitle-1">Produtividade Média</v-card-title>
    <v-card-text>
      <v-col class="d-flex flex-column">
        <span class="text-center text-h2">{{total}} horas</span>
        <span class="text-center text-subtitle1 mt-4">por chamado</span>
      </v-col>
    </v-card-text>
    <v-divider class="mx-8"></v-divider>
    <v-card-subtitle class="text-subtitle-2">Por tipo</v-card-subtitle>
    <v-card-text>
      <v-col class="d-flex flex-column">
        <v-badge
          dot
          inline
          left
          color="#EF5350"
        >
          <span class="ml-1 text-center text-subtitle2">
            Corretiva: {{corretiva}} horas
          </span>
        </v-badge>
        <v-badge
          dot
          inline
          left
          color="#42A5F5"
          class="my-1"
        >
          <span class="ml-1 text-center text-subtitle2">
            Preventiva: {{preventiva}} horas
          </span>
        </v-badge><v-badge
          dot
          inline
          left
          color="#66BB6A"
        >
          <span class="ml-1 text-center text-subtitle2">
            Melhoria: {{melhoria}} horas
          </span>
        </v-badge><v-badge
          dot
          inline
          left
          color="#78909C"
        >
          <span class="ml-1 text-center text-subtitle2">
            instalacao: {{instalacao}} horas
          </span>
        </v-badge>
        <span class="text-center text-subtitle1 mt-4">por chamado</span>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
  import relatorioService from '@/services/relatorioService'

  export default {
    watch: {
      filtrosStore() {
        this.getQuantidade()
      }
    },
    data () {
      return {
        total: '...',
        corretiva: '...',
        preventiva: '...',
        melhoria: '...',
        instalacao: '...',
        loading: false
      }
    },
    computed: {
      filtrosStore: {
        get(){
          return this.$store.state.sgmDashboardFiltros
        },
      }
    },
    mounted() {
      this.getQuantidade()
    },
    methods: {
      getQuantidade() {
        this.loading = true
        this.quantidade = null

        relatorioService.produtividadeMedia(this.filtrosStore).then((response) => {
          if (!response.erro) {
            this.total = this.formatarHora(response.data.total)
            this.corretiva = this.formatarHora(response.data.corretiva)
            this.preventiva = this.formatarHora(response.data.preventiva)
            this.melhoria = this.formatarHora(response.data.melhoria)
            this.instalacao = this.formatarHora(response.data.instalacao)
          }
        })
        .finally(() => {
          this.loading = false
        })
      },
      formatarHora(segundos) {
        if (!segundos) {
          return '0:00'
        }

        let sec_num = parseInt(segundos, 10)
        let hours   = Math.floor(sec_num / 3600)
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60)

        if (hours   < 10) {hours   = "0" + hours}
        if (minutes < 10) {minutes = "0" + minutes}
        
        return hours + ':' + minutes
      }
    }
  }
</script>