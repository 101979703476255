var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.formWidth},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"grey lighten-4",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Chamados de "+_vm._s(_vm.item.descricao))])],1),_c('v-card-text',{staticClass:"pl-0"},[_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.item.chamados),function(chamado){return _c('v-timeline-item',{key:chamado.id,attrs:{"color":((chamado.tipo == 'P'
              ? 'blue'
              : chamado.tipo == 'C'
              ? 'red'
              : 'green') + " lighten-2"),"large":"","fill-dot":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"text-h6 white--text"},[_vm._v(_vm._s(chamado.tipo))])]},proxy:true}],null,true)},[_c('v-card',{staticClass:"elevation-2",attrs:{"color":((chamado.tipo == 'P'
                ? 'blue'
                : chamado.tipo == 'C'
                ? 'red'
                : 'green') + " lighten-2")}},[_c('v-card-title',{staticClass:"text-h6 white--text font-weight-light"},[_vm._v(" "+_vm._s(chamado.descricao)+" ")]),_c('v-card-text',{staticClass:"white text--primary pt-2"},[_c('v-chip',{staticClass:"ma-1",attrs:{"outlined":""}},[_vm._v("Chamado "+_vm._s(chamado.tipo == "P" ? "preventivo" : chamado.tipo == "C" ? "corretivo" : "melhoria"))]),_c('v-chip',{staticClass:"ma-1",attrs:{"outlined":""}},[_vm._v("Protocolo: "+_vm._s(chamado.id))]),_c('v-chip',{staticClass:"ma-1",attrs:{"outlined":""}},[_vm._v("Aberto em: "+_vm._s(chamado.created_at))]),_c('v-subheader',[_vm._v(" Interações ")]),(!chamado.interacoes || !chamado.interacoes.length)?_c('v-chip',{staticClass:"ma-1",attrs:{"outlined":""}},[_vm._v(" Chamado sem interações até o momento ")]):_c('v-timeline',{attrs:{"dense":""}},_vm._l((chamado.interacoes),function(interacao){return _c('v-timeline-item',{key:interacao.id,attrs:{"large":"","color":"grey lighten-2"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',[(interacao.usuario.imagem)?_c('v-img',{attrs:{"src":interacao.usuario.imagem,"alt":interacao.usuario.nome}}):_c('v-icon',[_vm._v(" mdi-account ")])],1)]},proxy:true}],null,true)},[_c('v-row',{staticClass:"my-auto",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-chip',{staticClass:"white--text ml-0",attrs:{"color":"grey","label":"","small":""}},[_vm._v(" "+_vm._s(interacao.usuario.nome)+" ")]),_vm._v(" "+_vm._s(interacao.interacao)+" ")],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(interacao.imagem)?_c('v-btn',_vm._g({attrs:{"icon":"","color":"grey","target":"_blank"},on:{"click":function($event){return _vm.showImg(interacao)}}},on),[_c('v-icon',[_vm._v("mdi-image")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizar imagem")])]),_vm._v(" "+_vm._s(interacao.created_at)+" ")],1)],1)],1)}),1)],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.fecharForm}},[_vm._v("Fechar")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialogImagem),callback:function ($$v) {_vm.dialogImagem=$$v},expression:"dialogImagem"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary","dark":""}},[_vm._v(" Imagem "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogImagem = false;
            _vm.Imagemsel = '';}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('img',{attrs:{"id":"fotoschamados","src":_vm.Imagemsel}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }