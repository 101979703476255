
const menus = [
  {
    icone: 'mdi-home',
    titulo: 'Home',
    can: true,
    destino: '/',
    info: ''
  },
  {
    icone: 'mdi-view-dashboard',
    titulo: 'Dashboard',
    can: 'cliente_dashboard',
    destino: '/dashboard',
    info: 'Ver gráficos dos principais indicadores'
  },
  {
    icone: 'mdi-ticket',
    titulo: 'Chamados',
    can: 'cliente_chamados',
    destino: '/chamados',
    info: 'Solicitar atendimento ao grupo CWFirpo'
  },
  {
    icone: 'mdi-file-chart',
    titulo: 'Relatórios',
    can: 'cliente_relatorios',
    destino: '/relatorios',
    info: 'Ver o histórico de atendimentos por praças'
  },
  {
    icone: 'mdi-calendar-clock',
    titulo: 'Calendário',
    can: 'cliente_manutencoes',
    destino: '/manutencao',
    info: 'Ver o calendário de manutenções agendadas ou já realizadas'
  },
  {
    icone: 'mdi-phone-message',
    titulo: 'Plantão',
    can: 'cliente_plantoes', 
    // cliente_plantoes  permissão quando liberar
    destino: '/plantao',
    info: 'Ver técnicos de plantão'
  },
  {
    icone: 'mdi-account-supervisor',
    titulo: 'Administração',
    can: 'cliente_admin',
    destino: '/administracao',
    info: 'Gerenciar usuários da plataforma'
  },
]

export default menus