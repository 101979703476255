<template>
  <v-app>
    <nav-bar v-if="!['Login', 'RecuperarSenha'].includes($route.name)"></nav-bar>
    <side-bar v-if="!['Login', 'RecuperarSenha'].includes($route.name)"></side-bar>
    <bottom-sheet v-if="!['Login', 'RecuperarSenha'].includes($route.name)"></bottom-sheet>
    <snack-bar></snack-bar>
    
    <v-main>
      <v-container fluid v-if="!['Login', 'RecuperarSenha'].includes($route.name)">
        <router-view></router-view>
      </v-container>
      <router-view v-else></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'
import SideBar from '@/components/SideBar'
import BottomSheet from '@/components/BottomSheet'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'App',

  components: {
    NavBar,
    SideBar,
    BottomSheet,
    SnackBar
  },

  data: () => ({
    //
  }),
};
</script>
