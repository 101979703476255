<template>
  <v-container>
    <v-skeleton-loader
      v-if="loadingUsuarios"
      type="table"
    ></v-skeleton-loader>

    <v-data-table
      v-if="!loadingUsuarios"
      :headers="headers"
      :items="usuarios"
      :search="search"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Usuários</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mb-2"
            @click="openForm(formUsuario)"
          >
            Novo Usuário
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="openForm(formUsuario, item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="openFormPermissoes(formPermissoes, item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-shield
            </v-icon>
          </template>
          <span>Permissões</span>
        </v-tooltip>
      </template>

    </v-data-table>
  
    <form-usuario
      labelName="Usuário"
      formWidth="650px"
      :showDialog="formUsuario.show"
      :formItem="formUsuario.item"
      @formClose="closeForm(formUsuario)"
      @objetoSalvo="salvo()"
    ></form-usuario>

    <form-permissoes
      labelName="Permissões"
      formWidth="650px"
      :showDialog="formPermissoes.show"
      :formItem="formPermissoes.item"
      @formClose="closeForm(formPermissoes)"
      @objetoSalvo="getUsuarios()"
    ></form-permissoes>
  </v-container>
</template>

<script>
  import usuarioService from '@/services/usuarioService'
  import FormUsuario from '@/components/FormUsuario'
  import FormPermissoes from '@/components/FormPermissoes'

  export default {
    data: () => ({
      headers: [
        { text: 'Nome', value: 'nome', align: 'start' },
        { text: 'E-mail', value: 'login' },
        { text: 'Ações', value: 'acoes', sortable: false },
      ],
      search: '',
      loadingUsuarios: false,
      usuarios: [],
      idcliente: null,
      formUsuario: {
        show: false,
        item: {}
      },
      formPermissoes: {
        show: false,
        item: {}
      },
    }),
    components: {
      FormUsuario,
      FormPermissoes
    },
    mounted () {
      let usuario    = localStorage.getItem('usuario')
      this.idcliente = JSON.parse(usuario).idcliente || null

      this.getUsuarios()
    },
    methods: {
      getUsuarios() {
        this.usuarios = []
        this.loadingUsuarios = true

        usuarioService.getAll({idcliente: this.idcliente}).then((response) => {
          if (!response.erro) {
            this.usuarios = response.data
            this.loadingUsuarios = false
          }
        })
      },
      openForm(form, item) {
        let usuario = {idcliente: this.idcliente}

        if (item) {
          usuario.id = item.id
          usuario.nome = item.nome
          usuario.login = item.login
          usuario.bloqueado = !!item.bloqueado
        }

        form.item = usuario
        form.show = true
      },
      openFormPermissoes(form, item) {
        form.item = {
          idusuario: item.id,
          idcliente: item.idcliente,
          nome: item.nome
        }
        form.show = true
      },
      closeForm(form) {
        form.item = {}
        form.show = false
      },
      salvo() {
        this.$store.commit('snackMessage', {
          message: 'Lembrete: configure as permissões do usuário adicionado',
          color: 'info',
          timeout: 10000,
          closeButton: true,
          closeMessage: 'Obrigado'
        })

        this.getUsuarios()
      }
    },
  }
</script>