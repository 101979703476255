<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
      <v-card>
      <v-form ref="form" @submit.prevent="salvarUsuario">
        <v-card-title primary-title>Vinculando Praças à {{nome}}</v-card-title>
        <v-card-text>
          <v-autocomplete
            clearable
            :items="pracas"
            label="Praças"
            item-text="descricao"
            item-value="id"
            name="idpraca"
            autocomplete="off"
            v-model="item.idpraca"
          >
            <template v-slot:append-outer>
              <v-btn
                :disabled="!item.idpraca"
                color="success"
                fab
                small
                @click.stop="adicionarPraca"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>

          <v-data-table
            :headers="headers"
            :items="pracasUsuario"
            :loading="loadingPracasUsuario"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.acoes`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="deletePraca(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Remover praça</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row align="center" justify="end">
              <v-col cols="6">
                <v-btn block @click="fecharForm">Pronto</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import pracaService from '@/services/pracaService'
import FormPadrao from './FormPadrao'

export default {
  extends: FormPadrao,
  watch: {
    'item.idusuario'() {
      if (!this.item.idusuario) {
        return false
      }

      this.getPracasUsuario()
    },
  },
  data() {
    return {
      service: pracaService,
      idpraca: null,
      pracasUsuario: [],
      loadingPracasUsuario: false,
      headers: [
        { text: 'Praça', value: 'praca.descricao', align: 'start' },
        { text: 'Ações', value: 'acoes', sortable: false, align: 'end' },
      ],
    }
  },
  props: {
    pracas: {
      type: Array,
      default: () => {
        return []
      }
    },
    nome: {
      type: String,
      default: ''
    }
  },
  methods: {
    getPracasUsuario() {
      this.loadingPracasUsuario = true
      this.pracasUsuario = []

      pracaService.getPracasUsuario({iduser: this.item.idusuario}).then(resp => {
        if (resp.status == 200) {
          this.loadingPracasUsuario = false
          this.pracasUsuario = resp.data
        }
      })
    },
    adicionarPraca() {console.log('teste')
      //TODO ajustar isso aqui depois que o helfinha padronizar
      let obj = {
        iduser: this.item.idusuario,
        idpraca: this.item.idpraca
      }
      pracaService.addPracaUsuario(obj).then((response) => {
        if (response.status == 200 || response.status == 201) {
          this.item.idpraca = null
          this.getPracasUsuario()
        }
      })
      .catch((error) => {
        if (error) {
          this.$store.commit('snackMessage', {
            message: 'Desculpe, houve um erro ao adicionar a Praça',
            color: 'error',
            closeButton: true,
            closeMessage: 'Ok'
          })
        }
      })
    },
    deletePraca(item) {
      //TODO ajustar isso aqui depois que o helfinha padronizar
      let obj = {
        iduser: item.iduser,
        idpraca: item.idpraca
      }

      pracaService.delPracaUsuario(obj).then((response) => {
        if (response.status == 200) {
          this.getPracasUsuario()
        }
      })
    }
  }
}
</script>