<template>
  <v-container>
    <card-manutencao />
    <card-agenda-tecnicos class="mt-6" />
  </v-container>
</template>

<script>
  import CardManutencao from '@/components/CardManutencao'
  import CardAgendaTecnicos from '@/components/CardAgendaTecnicos'

  export default {
    name: 'Manutencao',
    components: {
      CardManutencao,
      CardAgendaTecnicos
    }
  }
</script>
