import crudService from "@/services/crudService";
import http from '@/plugins/axios';

class chamadoService extends crudService {

  constructor() {
    super();
    this.endpoint = '/chamado';
  }

  getAllResumo(params = {}) {
    return http.get('/chamado/resumo', {
      params
    })
  }

  getInteracoes(params = {}) {
    return http.get('/chamado_interacao', {
      params
    })
  }

  getAgendaTecnicos(params = {}) {
    return http.get('/chamado/agenda', {
      params
    })
  }
}

export default new chamadoService;