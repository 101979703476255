<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
    <v-card>
      <v-toolbar
        class="grey lighten-4"
        flat
      >
        <v-toolbar-title>{{item.resumo}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="white text--primary pt-2">
        <v-chip
          :class="`${item.tipo == 'P' ? 'blue' : item.tipo == 'C' ? 'red' : 'green'} lighten-2`"
          outlined
          class="ma-1"
        >
          Chamado {{item.tipo == 'P' ? 'preventivo' : item.tipo == 'C' ? 'corretivo' : 'melhoria'}}
        </v-chip>
        <v-chip outlined class="ma-1">Protocolo: {{item.id}}</v-chip>
        <v-chip outlined v-if="item.tecnico" class="ma-1">Técnico: {{item.tecnico.nome}}</v-chip><br>
        <v-chip outlined class="ma-1">Aberto em: {{item.criacao}}</v-chip>
        <v-chip outlined class="ma-1">Data prevista: {{item.previsao}}</v-chip>
        <v-chip outlined class="ma-1">Observação: {{item.relato}}</v-chip>
        <v-subheader>
          Interações
        </v-subheader>
        <v-chip
          v-if="!item.interacoes || !item.interacoes.length"
          outlined
          class="ma-1"
        >
          Chamado sem interações até o momento
        </v-chip>
        <v-timeline v-else dense>
          <v-timeline-item
          v-for="interacao in item.interacoes"
          :key="interacao.id"
          large
          color="grey lighten-2"
          >
          <template v-slot:icon>
            <v-avatar>
            <v-img
              v-if="interacao.usuario.imagem"
              :src="interacao.usuario.imagem"
              :alt="interacao.usuario.nome"></v-img>
            <v-icon v-else>
              mdi-account
            </v-icon>
            </v-avatar>
          </template>
          <v-row class="my-auto" justify="space-between">
            <v-col cols="7">
            <v-chip
              class="white--text ml-0"
              color="grey"
              label
              small
            >
              {{interacao.usuario.nome}}
            </v-chip>
            {{interacao.interacao}}
            </v-col>
            <v-col
            class="text-right"
            cols="5"
            >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
              <v-btn
                v-if="interacao.imagem"
                icon
                color="grey"
                v-on="on"
                :href="interacao.imagem.path"
                target="_blank"
              >
                <v-icon>mdi-image</v-icon>
              </v-btn>
              </template>
              <span>Ver imagem em uma nova aba</span>
            </v-tooltip>
            {{interacao.criacao}}
            </v-col>
          </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row align="center" justify="end">
            <v-col cols="12" sm="6">
              <v-btn block @click="fecharForm()">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormPadrao from './FormPadrao'
import chamadoService from '@/services/chamadoService'

export default {
  extends: FormPadrao,
  data() {
    return {
      service: chamadoService
    }
  }
}
</script>
