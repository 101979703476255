<template>
  <v-dialog v-model="showDialog" :width="formWidth" :persistent="formPersistent">
    <v-card>
      <v-form ref="form" @submit.prevent="alterarSenha">
        <v-card-title primary-title>{{cardTitle}}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Senha atual"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                v-model="item.senha"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nova senha"
                :type="show_new ? 'text' : 'password'"
                :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_new = !show_new"
                v-model="item.senha_nova"
                :rules="[rules.required, rules.minimumSizePassword, checkNewPassword]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Confirmar senha"
                :type="show_new ? 'text' : 'password'"
                :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_new = !show_new"
                v-model="item.conf_senha_nova"
                :rules="[checkPasswordRule]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-btn type="submit" dark block color="green" :loading="formLoading">Salvar</v-btn>
              </v-col>
              <v-col align="center" justify="center">
                <v-btn dark block color="red" @click="fecharForm">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import usuarioService from '@/services/usuarioService'
import FormPadrao from './FormPadrao'

export default {
  extends: FormPadrao,
  data() {
    return {
      service: usuarioService,
      show: false,
      show_new: false,
      checkPasswordRule: (value) => !!value && value === this.item.senha_nova || 'Senhas não conferem.',
      checkNewPassword: (value) => !!value && value !== this.item.senha || 'Senha nova é igual à atual.',
    }
  },
  methods: {
    alterarSenha() {
      if (this.$refs.form.validate()) {
        this.formLoading = true

        this.service
          .alterarSenha(this.item)
          .then((response) => {
            if (!response.erro) {
              let localUser = JSON.parse(localStorage.usuario)

              localUser.change_password = 0

              localStorage.usuario = JSON.stringify({...localUser})
              this.$store.commit('setTrocaSenha', false)

              this.retornaItem(response.data)
            }
            this.formLoading = false
          })
          .catch((error) => {
            this.getErrorMessage(error)
            this.formLoading = false
            this.item.senha = ''
            
            this.$refs.form.resetValidation()
          })
      }
    }
  },
  computed: {
    cardTitle() {
      return 'Alterando senha';
    }
  }
}
</script>