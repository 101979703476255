<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="sheet"
      persistent
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <div class="py-10">
          Este é o seu primeiro acesso. Por questões de segurança, você deve definir sua senha pessoal.
        </div>
        <v-btn
          class="mt-2"
          color="primary"
          @click="openForm(formSenha)"
        >
          Alterar Senha
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <form-senha
      :showDialog="formSenha.show"
      @objetoSalvo="senhaSalva(formSenha)"
      @formClose="closeForm(formSenha)"
      :formItem="formSenha.item"
    ></form-senha>
  </div>
</template>

<script>
import FormSenha from '@/components/FormSenha'

export default {
  name: 'BottomSheet',
  computed:{
    sheet:{
      get() {
        return this.$store.state.sheet
      },
      set() {}
    }
  },
  components: {
    FormSenha
  },
  data() {
    return {
      formSenha: {
        item: {},
        show: false
      }
    }
  },
  methods: {
    openForm(form) {
      this.$store.state.sheet = false

      let localUser = JSON.parse(localStorage.getItem('usuario'))

      form.item = {id: localUser.id}
      form.show = true
    },
    closeForm(form) {
      form.item = {}
      form.show = false
    },
    senhaSalva(form) {
      this.closeForm(form);

      this.$store.commit('snackMessage', {
        message: 'Senha alterada com sucesso',
        color: 'green',
        closeButton: true,
        closeMessage: 'Ok'
      })
    },
  }
}
</script>