import http from '@/plugins/axios';
import crudService from "@/services/crudService";

class pracaService extends crudService {

  constructor() {
    super();
    this.endpoint = '/praca';
  }

  getPracasUsuario(params = {}) {
    return http.get('/usuario/usuariospraca', {
      params
    })
  }

  addPracaUsuario(dados) {
    return http.post('/usuario/vincpraca', dados)
  }

  delPracaUsuario(params = {}) {
    const query = Object.keys(params).map((key) => {
      return key + '=' + params[key]
    }).join('&')

    return http.delete('/usuario/desvincpraca/?' + query)
  }
}

export default new pracaService;