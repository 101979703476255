<template>
  <v-card class="mx-2">
    <v-card-title class="text-subtitle-1">Praças</v-card-title>
    <v-card-text>
      <v-col class="d-flex flex-column">
        <span class="text-center text-h2">{{quantidade}}</span>
        <span class="text-center text-subtitle1 mt-4">Cadastrados</span>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
  import relatorioService from '@/services/relatorioService'

  export default {
    watch: {
      filtrosStore() {
        this.getQuantidade()
      }
    },
    data () {
      return {
        quantidade: '...',
        loading: false
      }
    },
    computed: {
      filtrosStore: {
        get(){
          return this.$store.state.sgmDashboardFiltros
        },
      }
    },
    mounted() {
      this.getQuantidade()
    },
    methods: {
      getQuantidade() {
        this.loading = true
        this.quantidade = null

        relatorioService.cadastros(this.filtrosStore).then((response) => {
          if (!response.erro) {
            this.quantidade = response.data.pracas
          }
        })
        .finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>