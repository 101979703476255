<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
    <v-card>
      <v-form ref="form" @submit.prevent="salvar">
        <v-card-title primary-title>{{ cardTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="text"
                label="Descrição"
                v-model="item.descricao"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-btn type="submit" dark block color="green" :loading="formLoading">Salvar</v-btn>
              </v-col>
              <v-col align="center" justify="center">
                <v-btn dark block color="red" @click="fecharForm">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    formItem() {
      this.resetaForm()
      this.item = this.formItem
    },
    formDelete() {
      if (this.formDelete) {
        this.delete()
      }
    },
  },
  data() {
    return {
      service: null,
      item: {},
      rules: {
        required: (value) => !!value || 'Campo Obrigatório.',
        minimumSizePassword: (value) => !!value && value.length >= 5 || 'A senha deve conter no mínimo 5 caracteres.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido.'
        },
        requiredGreterThanZero: (value) => !!value && Number(value) > 0 || 'Campo deve ser maior que zero!'
      },
      formLoading: false,
    }
  },
  props: {
    formWidth: {
      type: String,
      default: '600px',
    },
    formPersistent: {
      type: Boolean,
      default: true,
    },
    formDelete: {
      type: Boolean,
      default: false,
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      },
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    labelName: {
      type: String,
      default: '',
    },
    formDisable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    cardTitle() {
      const label = this.item.id ? 'Editando' : 'Adicionando'
      return this.labelName ? label + ' ' + this.labelName : label
    },
  },
  methods: {
    salvar() {
      if (this.$refs.form.validate()) {
        this.formLoading = true
        if (this.item.id) {
          this.service
            .set(this.item.id, this.item)
            .then((response) => {
              if (!response.erro) {
                this.retornaItem(response.data)
              }
              this.formLoading = false
            })
            .catch((error) => {
              this.getErrorMessage(error)
              this.formLoading = false
            })
        } else {
          this.service
            .new(this.item)
            .then((response) => {
              if (!response.erro) {
                this.retornaItem(response.data)
              }
              this.formLoading = false
            })
            .catch((error) => {
              this.getErrorMessage(error)
              this.formLoading = false
            })
        }
      }
    },
    delete() {
      if (window.confirm('Deseja mesmo deletar?') == true) {
        this.service
          .del(this.item.id)
          .then((res) => {console.log(res)
            this.deletaItem(this.item)
          })
          .catch((error) => {
            this.getErrorMessage(error)
          })
      } else {
        this.fecharForm()
      }
    },
    deletaItem(item) {
      this.$emit('objetoDeletado', item)
      this.fecharForm()
    },
    retornaItem(item) {
      this.$emit('objetoSalvo', item)
      this.fecharForm()
    },
    fecharForm() {
      this.resetaForm()
      this.$emit('formClose')
    },
    resetaForm() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    getErrorMessage(error) {
      const errorData = error.response && error.response.data
      const errorMessage =
        (errorData && errorData.mensagem) ||
        'Não foi possivel completar a operação'
      const errorFields = (error.response && errorData.erros) || {}
      const fieldsMessage = Object.values(errorFields).join('\n')

      const finalMessage = fieldsMessage
        ? errorMessage + '\n' + fieldsMessage
        : errorMessage

      //alert(finalMessage)
      this.$store.commit('snackMessage', {
        message: finalMessage,
        color: 'error',
        closeButton: true,
        closeMessage: 'Ok'
      })
    },
  },
}
</script>