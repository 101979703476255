import crudService from "@/services/crudService";

class ativoService extends crudService {

  constructor() {
    super();
    this.endpoint = '/ativo';
  }
}

export default new ativoService;