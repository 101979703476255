<template>
  <v-navigation-drawer
    color="primary"
    app
    v-model="drawer"
    enable-resize-watcher
  >
     <v-toolbar class="logoBar" flat>
      <v-img
        src="@/assets/logo.png"
        @click.stop="$router.push('/')"
        style="cursor: pointer"
      />
    </v-toolbar>
    <v-list rounded>
      <v-list-item
        v-for="item in menus"
        :key="item.titulo"
        :to="item.destino"
        link
        class="my-1"
      >
        <v-list-item-icon>
          <v-icon color="white" :size="22" v-if="$route.path == item.destino">{{ item.icone }}</v-icon>
          <v-icon color="white" :size="22" v-else>{{ item.icone }}-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-left text-body-1 white--text">{{ item.titulo }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import menus from '@/menus.js'

export default {
  name: 'SideBar',
  computed:{
    drawer:{
      get() {
        return this.$store.state.drawer
      },
      set(bool) {
        return bool
      }
    },
    menus() {
      var permissoes = this.$store.state.permissoes

      return menus.filter(e => {
        return e.can === true ? true : permissoes.includes(e.can)
      })
    }
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss">
  .ativo{
    color:#f00000 !important
  }
  .v-application--is-ltr .v-list-item__icon{
    margin-right: 8px !important;
  }  
  .v-list-group__items.v-list-item {
    padding-left: 42px !important;
  }
  .logoBar {
    height: 72px !important;
    background-color: white;
    width: 100%;
    background-color:#f5f5f5;
    border-bottom-color: #f00000 !important;
    border-bottom-width:2px;
    border-bottom-style:solid;
  }
</style>
