<template>
  <v-container>
    <v-skeleton-loader
      v-if="loadingPreChamados || loadingChamados"
      type="table"
    ></v-skeleton-loader>

    <v-data-table
      v-else
      :headers="headers"
      :items="preChamados"
      :search="search"
      :loading="openingForm"
      item-key="id"
      sort-by="criacao"
      sort-desc
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Chamados</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->
          <v-checkbox class="mt-5 ml-5" label="Apenas demandas próprias" v-model="filtros.apenasProprios" @change="getPreChamados" />
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mb-2"
            @click="openForm(formChamado)"
          >
            Novo Chamado
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="openForm(formChamadoDetalhes, item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Ver detalhes</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.protocolo_cliente`]="{ item }">
        {{item.protocolo_cliente || item.id }}
      </template>

    </v-data-table>

    <v-overlay :value="openingForm"></v-overlay>
  
    <form-chamado
      labelName="Chamado"
      formWidth="850px"
      :showDialog="formChamado.show"
      :formItem="formChamado.item"
      @formClose="closeForm(formChamado)"
      @objetoSalvo="salvo($event)"
    ></form-chamado>
    <form-chamado-detalhes
      labelName="Chamado"
      formWidth="850px"
      :showDialog="formChamadoDetalhes.show"
      :formItem="formChamadoDetalhes.item"
      @formClose="closeForm(formChamadoDetalhes)"
    ></form-chamado-detalhes>

    <v-dialog v-model="popupChamadoSalvo" width="500px" hide-overlay >
      <v-alert class="mb-0" border="top" color="success" dark>
        {{ popupChamadoSalvoText }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
  import preChamadoService from '@/services/preChamadoService'
  import chamadoService from '@/services/chamadoService'
  import FormChamado from '@/components/FormChamado'
  import FormChamadoDetalhes from '@/components/FormChamadoDetalhes'
  import moment from 'moment'

  export default {
    data: () => ({
      headers: [
        { text: 'Chamado', value: 'protocolo_cliente', align: 'start' },
        { text: 'Descrição', value: 'resumo' },
        { text: 'Data de criação', value: 'criacao' },
        { text: 'Data prevista', value: 'previsao' },
        { text: 'Situação', value: 'situacao' },
        { text: 'Ações', value: 'acoes', align: 'center', sortable: false },
      ],
      search: '',
      openingForm: false,
      loadingPreChamados: false,
      loadingChamados: false,
      preChamados: [],
      idcliente: null,
      formChamado: {
        show: false,
        item: {}
      },
      formChamadoDetalhes: {
        show: false,
        item: {}
      },
      filtros: {
        apenasProprios: true
      },
      popupChamadoSalvo: false,
      popupChamadoSalvoText: ''
    }),
    components: {
      FormChamado,
      FormChamadoDetalhes
    },
    mounted () {
      let usuario    = localStorage.getItem('usuario');
      this.idcliente = JSON.parse(usuario).idcliente || null;
      this.getPreChamados()
    },
    methods: {
      getPreChamados() {
        this.preChamados = []
        this.loadingPreChamados = true
        this.loadingChamados = true

        let parametros_prechamados = {
          idcliente: this.idcliente,
          pendentes: true,
          dinamico: true,
          proprios: this.filtros.apenasProprios ? true : false,
          colunas: [
            'id',
            'aprovacao',
            'protocolo_cliente',
            'tipo',
            'relato',
            'created_at',
            'reprovacao'
          ]
        }

        preChamadoService.getAll(parametros_prechamados).then((response) => {
          if (!response.erro) {
            response.data
              .filter(i => !!i.relato)
              .map(i => {
                i.situacao = i.reprovacao ? 'Rejeitado' : 'Em aprovação'
                i.resumo = i.relato.length > 35 ? i.relato.substring(0, 35) + '...' : i.relato
                i.criacao = moment(i.created_at).format('DD/MM/YYYY HH:mm')
                i.previsao = ''
                i.prechamado = true
                
                this.preChamados.push(i)
              })
          }
        }).finally(() => {
          this.loadingPreChamados = false
        })

        let parametros_chamados = {
          idcliente: this.idcliente,
          pendentes: true,
          dinamico: true,
          proprios: this.filtros.apenasProprios ? true : false,
          colunas: [
            'chamados.id',
            'chamados.descricao',
            'chamados.created_at',
            'chamados.data_prevista',
            'chamados.data_fechamento',
            'chamados.protocolo_cliente'
          ]
        }

        chamadoService.getAll(parametros_chamados).then((response) => {
          if (!response.erro) {
            response.data
              .map(i => {
                i.situacao = i.data_fechamento ? 'Encerrado' : 'Em andamento'
                i.resumo = i.descricao.length > 35 ? i.descricao.substring(0, 35) + '...' : i.descricao
                i.criacao = moment(i.created_at).format('DD/MM/YYYY HH:mm')
                i.previsao = i.data_prevista ? moment(i.data_prevista).format('DD/MM/YYYY') : ''
                i.prechamado = false
                
                this.preChamados.push(i)
              })
          }
        }).finally(() => {
          this.loadingChamados = false
        })
      },
      openForm(form, item) {
        if (!item) {
          form.item = {idcliente: this.idcliente}
          form.show = true

          return true
        }
        
        this.openingForm = true
        form.item = item ? JSON.parse(JSON.stringify(item)) : {idcliente: this.idcliente}

        if (form.item.prechamado) {
          form.item.interacoes = []
          form.show = true
          this.openingForm = false
        } else {
          chamadoService.getInteracoes({idchamado: form.item.id}).then((response) => {
            if (!response.erro) {
              form.item.interacoes = response.data
              form.show = true
            }
          }).catch((error) => {
            this.getErrorMessage(error)
          }).finally(() => {
            this.openingForm = false
          })
        }
      },
      closeForm(form) {
        form.show = false
        form.item = {}
      },
      salvo(objeto) {
        this.getPreChamados()

        this.popupChamadoSalvoText = 'Chamado número ' + objeto.protocolo_cliente + ' aberto e aguardando aprovação interna'
        this.popupChamadoSalvo = true
      }
    },
  }
</script>