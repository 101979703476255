
<template>
  <div>
    <v-skeleton-loader
      v-if="registros.loading"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items="registros.lista"
      :items-per-page="-1"
      hide-default-footer
      item-key="id"
      sort-by="praca"
      class="elevation-1 tabela-agenda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Agenda de técnicos</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="labelDates"
                  label="Semana"
                  prepend-icon="mdi-calendar-week"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @change="filtrar()"
                  class="mt-6"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                multiple
                no-title
                scrollable
                first-day-of-week="1"
                @click:date="selectWeek"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="filtrar()"
              :disabled="!dates.length"
            >
              Filtrar
          </v-btn>
        </v-toolbar>
      </template>

      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ value }">
        <div :key="header.value">
          <td v-if="header.value === 'praca'">
            {{ value }}
          </td>
          <div v-else>
            <v-chip
              v-for="(tecnico, i) in value"
              :key="i"
              label
              outlined
              small
              @click="showEvent(tecnico, $event)"
            >
              {{ tecnico.tecnico }}
            </v-chip>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      max-width="400"
      max-height="600"
      offset-x
      offset-y
    >
      <v-card
        min-width="350px"
        flat
      >
        <v-list
          subheader
          two-line
          dense
          v-if="tecnicoSelecionado.chamados && tecnicoSelecionado.chamados.length"
        >
          <v-subheader>
            {{tecnicoSelecionado.chamados.length}}
            {{tecnicoSelecionado.chamados.length == 1? 'Chamado' : 'Chamados'}}
          </v-subheader>

          <v-list-item v-for="chamado in tecnicoSelecionado.chamados" :key="chamado.id">
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{chamado.descricao}}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-subtitle>
                <v-btn @click="mostraid(chamado.id)">  {{chamado.id}}</v-btn>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import chamadoService from "@/services/chamadoService"
  import moment from 'moment'

export default {
  data() {
    return {
       clientes: {
        selecionado: null
      },
      registros: {
        lista: [],
        loading: false
      },
      id: {},
      search: "",
      headers: [
        { text: "Praça", value: "praca" },
        { text: "SEG", value: "seg" },
        { text: "TER", value: "ter" },
        { text: "QUA", value: "qua" },
        { text: "QUI", value: "qui" },
        { text: "SEX", value: "sex" },
        { text: "SAB", value: "sab" },
        { text: "DOM", value: "dom" }
      ],
      dates: [],
      semana: [
        'dom',
        'seg',
        'ter',
        'qua',
        'qui',
        'sex',
        'sab'
      ],
      menu: false,
      tecnicoSelecionado: {},
      selectedElement: null,
      selectedOpen: false
    };
  },
  computed: {
    labelDates() {
      const de = this.dates[0] ? 'De ' + moment(this.dates[0]).format('DD/MM/YYYY') : ''
      const ate = this.dates[this.dates.length - 1] ? ' até ' + moment(this.dates[this.dates.length - 1]).format('DD/MM/YYYY') : ''

      return de + ate
    }
  },
   mounted() {
    let usuario = localStorage.getItem('usuario')
    this.clientes.selecionado = JSON.parse(usuario).idcliente || null
  },
  methods: {
   
    getAgenda() {
      this.loading = true;
      this.registros.lista = [];

      this.headers = [{ text: "Praça", value: "praca" }]

      this.dates.forEach(val => {
        let datetime = val + ' 00:00:00'
        const date = new Date(datetime)

        this.headers.push({
          text: `${this.semana[date.getDay()].toUpperCase()} (${moment(datetime).format('DD/MM')})`,
          value: moment(datetime).format('DD/MM')
        })
      })

      chamadoService.getAgendaTecnicos({idcliente: this.clientes.selecionado, data: this.dates[0]}).then((response) => {
        if (response.status === 200) {
          this.registros.lista = response.data;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    selectWeek(selectedDate) {
      selectedDate = selectedDate + ' 00:00:00'
      let date = new Date(selectedDate)
      let atual
      let semana = date.getDay()

      if (semana === 0) {
        atual = moment(selectedDate).subtract(6, 'days').format('YYYY-MM-DD')
      } else {
        atual = moment(selectedDate).subtract(semana - 1, 'days').format('YYYY-MM-DD')
      }

      this.dates = []
      for (let i = 0; i < 7; i++) {
        this.dates.push(moment(atual).add(i, 'days').format('YYYY-MM-DD'))
      }
    },
     filtrar() {
      this.getAgenda()
    },
    showEvent (tecnico, event) {
      const open = () => {
        this.tecnicoSelecionado = tecnico
        this.selectedElement = event.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      event.stopPropagation()
    }
  }
};
</script>

<style>
  .theme--light.v-data-table thead tr:last-child th, 
   .theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
   .theme--light.v-data-table tbody tr td,
   .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
      border-right: 1px dotted rgba(0,0,0,.12);
   }
</style>