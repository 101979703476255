import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import chamadoService from '@/services/chamadoService'
import preChamadoService from '@/services/preChamadoService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    menus: [],
    permissoes: [],
    chamados: {
      loading: false,
      data: []
    },
    preChamados: {
      loading: false,
      data: []
    },
    sheet: false,
    snackbar: {
      show: false
    },
    sgmDashboardFiltros: {
      idcliente: null,
      dataini: null,
      datafim: null
    }
  },
  mutations: {
    changeDrawer(state, bool) {
      state.drawer = bool === undefined ? !state.drawer : bool
    },
    setPermissoes(state, permissoes) {
      state.permissoes = permissoes
    },
    setChamados(state, chamados) {
      state.chamados = chamados
    },
    setPreChamados(state, preChamados) {
      state.preChamados = preChamados
    },
    snackMessage(state, config) {
      config.message = config.message || ''
      config.closeMessage = config.closeMessage || 'Fechar'
      config.show = true

      state.snackbar = config
    },
    setTrocaSenha(state, val) {
      state.sheet = val
    },
  },
  getters: {
    getChamados: ({ chamados }) => {
      return chamados
    },
    getPreChamados: ({ preChamados }) => {
      return preChamados
    }
  },
  actions: {
    getChamados({ commit }, { idcliente, loading }) {
      commit('setChamados', {
        'loading': loading,
        'data': []
      })

      chamadoService.getAll({'idcliente': idcliente, 'praca': true}).then((response) => {
        if (response.status == 200) {
          commit('setChamados', {
            'loading': false,
            'data': response.data
          })
        }
      })
    },
    getPreChamados({ commit }, { idcliente, loading }) {
      commit('setChamados', {
        'loading': loading,
        'data': []
      })

      preChamadoService.getAll({'idcliente': idcliente, 'entidade': true}).then((response) => {
        if (response.status == 200) {
          commit('setPreChamados', {
            'loading': false,
            'data': response.data
          })
        }
      })
    }
  },
  modules: {
  },
  plugins: [
      createPersistedState()
  ]
})
