<template>
  <v-dialog v-model="showDialog" :width="formWidth">
    <v-card>
      <v-toolbar class="grey lighten-4" flat>
        <v-toolbar-title>Chamados de {{ item.descricao }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pl-0">
        <v-timeline align-top dense>
          <v-timeline-item
            v-for="chamado in item.chamados"
            :key="chamado.id"
            :color="`${
              chamado.tipo == 'P'
                ? 'blue'
                : chamado.tipo == 'C'
                ? 'red'
                : 'green'
            } lighten-2`"
            large
            fill-dot
          >
            <template v-slot:icon>
              <span class="text-h6 white--text">{{ chamado.tipo }}</span>
            </template>
            <v-card
              class="elevation-2"
              :color="`${
                chamado.tipo == 'P'
                  ? 'blue'
                  : chamado.tipo == 'C'
                  ? 'red'
                  : 'green'
              } lighten-2`"
            >
              <v-card-title class="text-h6 white--text font-weight-light">
                {{ chamado.descricao }}
              </v-card-title>
              <v-card-text class="white text--primary pt-2">
                <v-chip outlined class="ma-1"
                  >Chamado
                  {{
                    chamado.tipo == "P"
                      ? "preventivo"
                      : chamado.tipo == "C"
                      ? "corretivo"
                      : "melhoria"
                  }}</v-chip
                >
                <v-chip outlined class="ma-1"
                  >Protocolo: {{ chamado.id }}</v-chip
                >
                <v-chip outlined class="ma-1"
                  >Aberto em: {{ chamado.created_at }}</v-chip
                >
                <v-subheader> Interações </v-subheader>
                <v-chip
                  v-if="!chamado.interacoes || !chamado.interacoes.length"
                  outlined
                  class="ma-1"
                >
                  Chamado sem interações até o momento
                </v-chip>
                <v-timeline v-else dense>
                  <v-timeline-item
                    v-for="interacao in chamado.interacoes"
                    :key="interacao.id"
                    large
                    color="grey lighten-2"
                  >
                    <template v-slot:icon>
                      <v-avatar>
                        <v-img
                          v-if="interacao.usuario.imagem"
                          :src="interacao.usuario.imagem"
                          :alt="interacao.usuario.nome"
                        ></v-img>
                        <v-icon v-else> mdi-account </v-icon>
                      </v-avatar>
                    </template>
                    <v-row class="my-auto" justify="space-between">
                      <v-col cols="7">
                        <v-chip
                          class="white--text ml-0"
                          color="grey"
                          label
                          small
                        >
                          {{ interacao.usuario.nome }}
                        </v-chip>
                        {{ interacao.interacao }}
                      </v-col>
                      <v-col class="text-right" cols="5">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="interacao.imagem"
                              icon
                              color="grey"
                              v-on="on"
                              @click="showImg(interacao)"
                              target="_blank"
                            >
                              <v-icon>mdi-image</v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar imagem</span>
                        </v-tooltip>
                        {{ interacao.created_at }}
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-row align="center" justify="end">
            <v-col cols="12" sm="6">
              <v-btn block @click="fecharForm">Fechar</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogImagem" max-width="700px">
      <v-card>
        <v-toolbar dense color="primary" dark>
          Imagem
          <v-spacer />
          <v-btn
            icon
            dark
            @click="
              dialogImagem = false;
              Imagemsel = '';
            "
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <img id="fotoschamados" :src="Imagemsel" />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import FormPadrao from "./FormPadrao";

export default {
  extends: FormPadrao,
  data() {
    return {
      dialogImagem: false,
      Imagemsel: "",
    };
  },
  methods: {
    showImg(interacao) {
      this.Imagemsel = interacao.imagem.path;
      this.dialogImagem = true;
    },
  },
};
</script>
<style scoped>
  #fotoschamados{
    height: 100%;
   width: 100%;
  }
</style>