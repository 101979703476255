var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loadingPreChamados || _vm.loadingChamados)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.preChamados,"search":_vm.search,"loading":_vm.openingForm,"item-key":"id","sort-by":"criacao","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Chamados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Busca","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-checkbox',{staticClass:"mt-5 ml-5",attrs:{"label":"Apenas demandas próprias"},on:{"change":_vm.getPreChamados},model:{value:(_vm.filtros.apenasProprios),callback:function ($$v) {_vm.$set(_vm.filtros, "apenasProprios", $$v)},expression:"filtros.apenasProprios"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openForm(_vm.formChamado)}}},[_vm._v(" Novo Chamado ")])],1)]},proxy:true},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openForm(_vm.formChamadoDetalhes, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])])]}},{key:"item.protocolo_cliente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.protocolo_cliente || item.id)+" ")]}}],null,true)}),_c('v-overlay',{attrs:{"value":_vm.openingForm}}),_c('form-chamado',{attrs:{"labelName":"Chamado","formWidth":"850px","showDialog":_vm.formChamado.show,"formItem":_vm.formChamado.item},on:{"formClose":function($event){return _vm.closeForm(_vm.formChamado)},"objetoSalvo":function($event){return _vm.salvo($event)}}}),_c('form-chamado-detalhes',{attrs:{"labelName":"Chamado","formWidth":"850px","showDialog":_vm.formChamadoDetalhes.show,"formItem":_vm.formChamadoDetalhes.item},on:{"formClose":function($event){return _vm.closeForm(_vm.formChamadoDetalhes)}}}),_c('v-dialog',{attrs:{"width":"500px","hide-overlay":""},model:{value:(_vm.popupChamadoSalvo),callback:function ($$v) {_vm.popupChamadoSalvo=$$v},expression:"popupChamadoSalvo"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","color":"success","dark":""}},[_vm._v(" "+_vm._s(_vm.popupChamadoSalvoText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }