<template>
  <v-card class="mx-2">
    <v-card-title>Timeline de Chamados</v-card-title>
    <v-card-text class="px-8">
      <canvas id="chartTimelineChamados"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
  import relatorioService from '@/services/relatorioService'
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    watch: {
      filtrosStore() {
        this.getData()
      }
    },
    data () {
      return {
        chart: null,
        loaded: false,
        datacollection: {
          labels: [],
          datasets: [
            {
              label: 'Corretiva',
              data: [],
              borderColor: '#EF5350',
              backgroundColor: '#EF5350',
              fill: true
            },
            {
              label: 'Preventiva',
              data: [],
              borderColor: '#42A5F5',
              backgroundColor: '#42A5F5',
              fill: true
            },
            {
              label: 'Melhoria',
              data: [],
              borderColor: '#66BB6A',
              backgroundColor: '#66BB6A',
              fill: true
            },
            {
              label: 'Instalção',
              data: [],
              borderColor: '#78909C',
              backgroundColor: '#78909C',
              fill: true
            },
          ]
        },
        options: {
          elements: {
            line: {
              tension: 0.3
            }
          },
          responsive: true,
          plugins: {
            title: {
              display: false
            },
            tooltip: {
              mode: 'index'
            },
            legend: {
              position: 'bottom'
            }
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          scales: {
            x: {
              title: {
                display: false
              }
            },
            y: {
              stacked: true,
              title: {
                display: false
              }
            }
          }
        }
      }
    },
    computed: {
      filtrosStore: {
        get(){
          return this.$store.state.sgmDashboardFiltros
        },
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.loaded = false

        if (this.chart) {
          this.chart.destroy()
        }

       relatorioService.timelineChamados(this.filtrosStore).then((response) => {
          if (!response.erro) {
            this.datacollection.labels = []
            this.datacollection.datasets[0].data = []
            this.datacollection.datasets[1].data = []
            this.datacollection.datasets[2].data = []
            this.datacollection.datasets[3].data = []
            
            response.data.map(i => {
              this.datacollection.labels.push(i.mes)
              this.datacollection.datasets[0].data.push(i.corretiva)
              this.datacollection.datasets[1].data.push(i.preventiva)
              this.datacollection.datasets[2].data.push(i.melhoria)
              this.datacollection.datasets[3].data.push(i.instalacao)
            })

            this.chart = new Chart(
              document.getElementById('chartTimelineChamados'),
              {
                type: 'line',
                data: this.datacollection,
                options: this.options
              }
            )

            this.loaded = true
          }
        })
      }
    }
  }
</script>