import http from '@/plugins/axios';

export default class crudService {

  constructor() {
    this.endpoint = '/endpoint';
  }

  getAll(params = {}) {
    return http.get(this.endpoint, {
      params
    })
  }

  get(id) {
    return http.get(this.endpoint + '/' + id)
  }

  set(id, dados) {
    /*const formData = new FormData();
    formData.append('_method', 'PUT')
    Object.keys(dados).forEach(key => {
      formData.append(key, dados[key])
    })*/
    return http.put(this.endpoint + '/' + id, /*formData*/ dados)
  }

  new(dados) {
    /*const formData = new FormData();
    Object.keys(dados).forEach(key => {
      formData.append(key, dados[key])
    })*/

    return http.post(this.endpoint, /*formData*/ dados)
  }

  del(id) {
    return http.delete(this.endpoint + '/' + id)
  }
}