<template>
  <v-container class="custom-container" fluid>
    <v-toolbar flat class="ma-0">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="periodo"
            label="Período de datas"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            class="mx-2"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          no-title
          scrollable
          range
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dates = []"
          >
            Limpar
          </v-btn>
          <v-btn
            text
            @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(dates)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn
        plain
        color="primary"
        @click="filtrar()"
      >
        Filtrar
      </v-btn>
    </v-toolbar>
    <v-row
      class="mb-6"
      no-gutters
    >
      <v-col cols="4">
        <chamados></chamados>
      </v-col>
      <v-col cols="8">
        <timeline-chamados></timeline-chamados>
      </v-col>
    </v-row>
    <v-row
      class="mb-6"
      no-gutters
    >
      <v-col cols="8">
        <v-row no-gutters>
          <v-col cols="4">
            <pracas></pracas>
          </v-col>
          <v-col cols="4">
            <pistas></pistas>
          </v-col>
          <v-col cols="4">
            <ativos></ativos>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <produtividade-media></produtividade-media>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chamados from '@/components/dashboard/Chamados.vue'
import Pracas from '@/components/dashboard/Pracas.vue'
import Pistas from '@/components/dashboard/Pistas.vue'
import Ativos from '@/components/dashboard/Ativos.vue'
import TimelineChamados from '@/components/dashboard/TimelineChamados.vue'
import ProdutividadeMedia from '@/components/dashboard/ProdutividadeMedia.vue'
import moment from 'moment'

export default {
  name: 'Home',
  data() {
    return {
      clientes: {
        selecionado: null,
        loading: false,
        lista: []
      },
      dates: [],
      menu: false,
      idcliente: null
    }
  },
  computed: {
    periodo: {
      get() {
        const de = this.dates[0] ? 'De ' + moment(this.dates[0]).format('DD/MM/YYYY') : ''
        const ate = this.dates[1] ? ' até ' + moment(this.dates[1]).format('DD/MM/YYYY') : ''

        return de + ate
      },
      set() {

      }
    }
  },
  components: {
    Chamados,
    Pracas,
    Pistas,
    Ativos,
    TimelineChamados,
    ProdutividadeMedia
  },
  mounted() {
    let usuario    = localStorage.getItem('usuario')
    this.idcliente = JSON.parse(usuario).idcliente || null

    this.$store.state.sgmDashboardFiltros.idcliente = this.idcliente
  },
  methods: {
    filtrar() {
      this.$store.state.sgmDashboardFiltros = {
        idcliente: this.idcliente,
        dataini: (this.dates.length >= 1) ? this.dates[0] : null,
        datafim: (this.dates.length >= 2) ? this.dates[1] : null
      }
    }
  }
}
</script>

<style scoped>
  .custom-container {
    max-width: 1800px;
  }
</style>