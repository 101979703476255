import http from '@/plugins/axios';

class relatorioService {

  chamados(params = {}) {
    return http.get('dashboard/resumo', {
      params
    })
  }

  tecnicosMaisAcionados(params = {}) {
    return http.get('dashboard/tecnicosmaisacionados', {
      params
    })
  }

  cadastros(params = {}) {
    return http.get('dashboard/cadastros', {
      params
    })
  }

  timelineChamados(params = {}) {
    return http.get('dashboard/timelinechamados', {
      params
    })
  }

  produtividadeMedia(params = {}) {
    return http.get('dashboard/produtividademedia', {
      params
    })
  }
}

export default new relatorioService;