import crudService from "@/services/crudService";

class plantaoService extends crudService {

  constructor() {
    super();
    this.endpoint = '/plantao';
  }
}

export default new plantaoService;