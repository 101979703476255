<template>
  <div class="fundo fill-height">
    <v-card width="600px">
      <v-card-title>
        <v-img height="80" contain src="@/assets/logo.png"></v-img>
      </v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <v-card-text>
              <v-form
                @submit.prevent="doLogin"
                ref="formulario"
                id="form"
                lazy-validation
              >
                <p
                  v-if="recuperarSenha"
                  class="text-center"
                >Informe seu e-mail.<br>Validaremos seu cadastro e lhe enviaremos um e-mail com instruções para a recuperação de senha</p>
                <v-text-field
                  filled
                  label="E-mail"
                  prepend-icon="mdi-at"
                  required
                  v-model="login"
                  :rules="[requiredRule, emailRule]"
                />
                <v-text-field
                  v-if="!recuperarSenha"
                  filled
                  label="Senha"
                  prepend-icon="mdi-lock"
                  required
                  v-model="senha"
                  type="password"
                  :rules="!recuperarSenha ? [requiredRule] : []"
                  :hint="hintCaps"
                  v-on:keyup="checkCaps"
                />
                <p
                  v-if="!recuperarSenha"
                  style="cursor: pointer"
                  @click="recuperarSenha = true"
                  class="ma-0 text-center"
                >Esqueceu a senha?</p>
                <p
                  v-else
                  style="cursor: pointer"
                  @click="recuperarSenha = false"
                  class="ma-0 text-center"
                >Voltar para o login</p>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" justify="center">
            <v-btn v-if="!recuperarSenha" type="submit" form="form" :loading="loadingBtnEntrar">
              <v-icon left>mdi-exit-to-app</v-icon>Entrar
            </v-btn>
            <v-btn
              v-else
              :loading="loadingBtnRecuperar"
              @click="solicitarRecuperacaoSenha()"
            >
              <v-icon left>mdi-lock-reset</v-icon>Recuperar Senha
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <span class="span mt-2">Grupo CWFirpo, 2021</span>
  </div>
</template>

<script>
import authService from "@/services/authService"
import store from '@/store'

export default {
  name: "Login",
  data: () => {
    return {
      login: "",
      senha: "",
      recuperarSenha: false,
      requiredRule: (v) => !!v || "Campo é obrigatório",
      emailRule: (v) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(v) || 'E-mail inválido.'
      },
      hintCaps: "",
      loadingBtnEntrar: false,
      loadingBtnRecuperar: false
    };
  },

  methods: {
    doLogin() {
      //gambit
      if (this.recuperarSenha) {
        return this.solicitarRecuperacaoSenha()
      }

      if (!this.$refs.formulario.validate()) {
        return false
      }

      this.loadingBtnEntrar = true;

      authService
        .login(this.login, this.senha)
        .then((resp) => {
          this.$cookies.set("token", resp.data.token);
          this.$cookies.set("expires_at", resp.data.expires_at);

          let expires_date = new Date(resp.data.expires_at);
          localStorage.setItem(
            "expires_date",
            expires_date.getTime()
          );

          authService.getPermUsuario(resp.data.user.id).then(perms => {
            if (perms.status == 200) {
              store.commit('setPermissoes', perms.data)
            }
          })

          if (resp.data.user.change_password) {
            store.commit('setTrocaSenha', true)
          }

          store.commit('changeDrawer', true);

          localStorage.usuario = JSON.stringify({...resp.data.user});

          store.dispatch('getChamados', {
            idcliente: resp.data.user.idcliente,
            loading: true
          })
          store.dispatch('getPreChamados', {
            idcliente: resp.data.user.idcliente,
            loading: true
          })

          if (this.$route.query) {
            if (this.$route.query.pagina) {
              this.$router.push({ name: this.$route.query.pagina });
            } else if (this.$route.query.path) {
              this.$router.push({path: decodeURIComponent(this.$route.query.path)});
            } else {
              this.$router.push({ name: "Home" });
            }
            
          } else {
            this.$router.push({ name: "Home" });
          }
        }).catch((error) => {
          console.log(error);
          this.$store.commit('snackMessage', {
            message: 'Usuário ou senha incorreto!',
            closeButton: true,
            closeMessage: 'Ok'
          })

          this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
          localStorage.clear();
        }).finally(() => {
          this.loadingBtnEntrar = false;
        });
    },
    checkCaps(event) {
      this.hintCaps = event.getModifierState('CapsLock') ? 'Caps Lock ativo' : '';
    },
    solicitarRecuperacaoSenha() {
      if (!this.$refs.formulario.validate()) {
        return false
      }

      this.loadingBtnRecuperar = true

      authService.recuperarSenha(this.login)
        .then((response) => {
          if (response.status == 200) {
            this.$store.commit('snackMessage', {
              message: 'Solicitação realizada. Verifique seu e-mail',
              color: 'success',
              closeButton: true,
              closeMessage: 'Obrigado'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('snackMessage', {
            message: 'Não foi possível concluir sua solicitação!',
            closeButton: true,
            closeMessage: 'Ok'
          })
        })
        .finally(() => {
          this.$refs.formulario.resetValidation()
          this.loadingBtnRecuperar = false
          this.recuperarSenha = false
        })
    }
  },

  mounted() {
    this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
    localStorage.clear();
  },
};
</script>

<style scoped>
.span {
  font-size: 16px;
  color: white;
  /*position: fixed;*/
  bottom: 15px;
}
.fundo {
  display: inline;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4d4946;
}
</style>