<template>
  <v-app-bar flat class="menu" app >
    <v-btn v-if="$route.path != '/admin'" @click.stop="menuLateral" icon color="#f00000"><v-icon>mdi-menu</v-icon></v-btn>
    <v-btn v-else to="home" icon color="#f00000"><v-icon>mdi-home</v-icon></v-btn>
    <v-spacer></v-spacer>
    <h5 class="mr-2">OLÁ, {{user.primeiroNome}} ({{user.cliente.nome}})</h5>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar style="cursor: pointer"
          v-on="on">
          <v-icon v-if="!foto">mdi-account</v-icon>
          <v-img
            v-else
            :src="foto"
            contain
          />
        </v-avatar>
      </template>
      <v-list>
        <v-list-item
          @click="openForm(formSenha)">
          <v-list-item-icon>
            <v-icon 
              v-text="'mdi-form-textbox-password'"
              color="red">
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="logout()">
          <v-list-item-icon>
            <v-icon 
              v-text="'mdi-logout'"
              color="red">
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <form-senha
      :showDialog="formSenha.show"
      @objetoSalvo="senhaSalva(formSenha)"
      @formClose="closeForm(formSenha)"
      :formItem="formSenha.item"
    ></form-senha>
  </v-app-bar>
</template>

<script>
  import FormSenha from '@/components/FormSenha'

  export default {
    name: 'NavBar',
    data() {
      return {
        formSenha: {
          item: {},
          show: false
        }
      }
    },
    mounted() {
      //comecou a ranheira
      document.querySelector('header.menu div.v-toolbar__content').setAttribute('style', 'height: 72px')
    },
    methods:{
      menuLateral(){
        this.$store.commit('changeDrawer')
      },
      openForm(form) {
        form.item = {id: this.user.id}
        form.show = true
      },
      closeForm(form) {
        form.item = {}
        form.show = false
      },
      senhaSalva(form) {
        this.closeForm(form);

        this.$store.commit('snackMessage', {
          message: 'Senha alterada com sucesso',
          color: 'green',
          closeButton: true,
          closeMessage: 'Ok'
        })
      },
      logout() {
        this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
        localStorage.clear();

        this.$router.push({name: 'Login'})
      }
    },
    components: {
      FormSenha
    },
    computed:{
      user() {
        try {
          let localUser          = JSON.parse(localStorage.getItem('usuario'))
          localUser.primeiroNome = localUser.nome.split(' ', 1)[0].toUpperCase()

          return localUser
        } catch (error) {
          return {}
        }
      },
      foto() {
        return this.$store.state.fotoPerfil
      }
    }
  }
</script>

<style scoped>
  .sgd-logo{
    color:#f00000;
    font-weight:bold;
    font-size:1.25rem;
    letter-spacing:0.5px
  }
  .menu{
    height: 72px !important;
    background-color: white !important;
    border-bottom-color: #f00000 !important;
    border-bottom-width:2px;
    border-bottom-style:solid
  }
</style>