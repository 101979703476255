import Home from '@/views/Home'
import Dashboard from '@/views/Dashboard'
import Chamados from '@/views/Chamados'
import Relatorios from '@/views/Relatorios'
import Manutencao from '@/views/Manutencao'
import Administracao from '@/views/Administracao'
import Plantao from '@/views/Plantao'
import Login from '@/views/Login'
import RecuperarSenha from '@/views/RecuperarSenha'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'cliente_dashboard',
    component: Dashboard
  },
  {
    path: '/chamados',
    name: 'cliente_chamados',
    component: Chamados
  },
  {
    path: '/relatorios',
    name: 'cliente_relatorios',
    component: Relatorios
  },
  {
    path: '/manutencao',
    name: 'cliente_manutencoes',
    component: Manutencao
  },
  {
    path: '/administracao',
    name: 'cliente_admin',
    component: Administracao
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/recuperarsenha',
    name: 'RecuperarSenha',
    component: RecuperarSenha
  },
  {
    path: '/plantao',
    name: 'cliente_plantoes',
    component: Plantao
  },
]

export default routes