import http from '@/plugins/axios';

class authService {

  login(login, senha) {
    return http.post('/login', {
      login: login,
      password: senha
    });
  }

  recuperarSenha(email) {
    return http.post('/usuario/esquecisenha', {
      'email': email
    })
  }

  confirmarRecuperacaoSenha(data) {
    return http.post('/usuario/novasenha ', data)
  }

  dadosUsuario(id) {
    return http.get('/usuario/' + id)
  }

  isAuthenticated(){
    let now = new Date();
    return localStorage.getItem('expires_date') < now.getTime() ? false : true;
  }

  getPermUsuario(id) {
    return http.get('/permissoes/usuarios/' + id)
  }

  getPermissoes() {
    return http.get('/permissoes')
  }

  setPermissao(idUser, idPerm){
    return http.post('/permissoes/usuarios', {
      idusuario: idUser,
      idpermissao: idPerm
    })
  }

  deletePermissao(idUser, idPerm){
    return http.delete('/permissoes/usuarios', {
      params: {
        idusuario: idUser,
        idpermissao: idPerm
      }
    })
  }
      
}

export default new authService;
