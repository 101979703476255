<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
    <v-card>
      <v-form ref="form" @submit.prevent="salvarUsuario">
        <v-card-title primary-title>Permissões de {{item.nome}}</v-card-title>
        <v-card-text>
          <v-checkbox
            class="my-0"
            v-for="(permissao, i) in permissoes"
            :key="i"
            v-model="permissao.checked"
            :label="permissao.descricao"
            :disabled="loadingPermissoes"
            @click="setPermissao(permissao)"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row align="center" justify="end">
              <v-col cols="6" v-if="permissoes.cliente_pracasvinculadas.checked">
                <v-btn block @click="openForm(formPracas, item)">Vincular Praças</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block @click="fecharFormPermissoes">Pronto</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>

    <form-pracas
      labelName="Permissões"
      formWidth="650px"
      :showDialog="formPracas.show"
      :formItem="formPracas.item"
      @formClose="closeForm(formPracas)"
      :pracas="pracas"
      :nome="item.nome"
    ></form-pracas>
  </v-dialog>
</template>

<script>
import authService from '@/services/authService'
import pracaService from '@/services/pracaService'
import FormPadrao from './FormPadrao'
import FormPracas from '@/components/FormPracas'

export default {
  extends: FormPadrao,
  watch: {
    'item.idusuario'() {
      if (!this.item.idusuario) {
        return false
      }

      this.getPracas()

      this.loadingPermissoes = true

      authService.getPermUsuario(this.item.idusuario).then(resp => {
        if (resp.status == 200) {
          for (let i in this.permissoes) {
            this.permissoes[i].checked = resp.data.includes(i)

            this.loadingPermissoes = false
          }
        }
      })
    },
  },
  data() {
    return {
      service: authService,
      permissoes: {
        cliente_dashboard: {descricao: 'Menu de Dashboard', checked: false},
        cliente_chamados: {descricao: 'Menu de Chamados', checked: false},
        cliente_relatorios: {descricao: 'Menu de Relatórios', checked: false},
        cliente_manutencoes: {descricao: 'Menu de Manutenções', checked: false},
        cliente_admin: {descricao: 'Menu administrativo', checked: false},
        cliente_pracasvinculadas: {descricao: 'Restrição por Praças', checked: false},
        cliente_plantoes: {descricao: 'Plantões', checked: false}
      },
      loadingPermissoes: false,
      formPracas: {
        item: {},
        show: false
      },
      pracas: []
    }
  },
  components: {
    FormPracas
  },
  mounted() {
    this.getPermissoes()
    //this.getPracas()
  },
  methods: {
    setPermissao(permissao) {
      if (permissao.checked) {
        authService.setPermissao(this.item.idusuario, permissao.id)
      } else {
        authService.deletePermissao(this.item.idusuario, permissao.id)
      }
    },
    getPermissoes() {
      authService.getPermissoes().then((resp) => {
        resp.data.forEach((val) => {
          if (Object.prototype.hasOwnProperty.call(this.permissoes, val.name)) {
            this.permissoes[val.name].id = val.id
          }
        })
      })
    },
    getPracas() {
      this.pracas = []

      pracaService.getAll({idcliente: this.item.idcliente}).then((response) => {
        if (!response.erro) {
          this.pracas = response.data
        }
      })
    },
    openForm(form, item) {
      form.item = item ? JSON.parse(JSON.stringify(item)) : {idcliente: this.idcliente}
      form.show = true
    },
    closeForm(form) {
      form.item = {}
      form.show = false
    },
    fecharFormPermissoes() {
      this.fecharForm()

      for (let i in this.permissoes) {
        this.permissoes[i].checked = false
      }
    }
  }
}
</script>