<template>
  <v-dialog
    v-model="showDialog"
    :width="formWidth"
    :persistent="formPersistent"
  >
      <v-card>
      <v-form ref="form" @submit.prevent="salvarUsuario">
        <v-card-title primary-title>{{ cardTitle }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                type="text"
                label="Nome"
                v-model="item.nome"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="text"
                label="E-mail"
                v-model="item.login"
                :rules="[rules.required, rules.email]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Senha"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                v-model="item.password"
                :rules="item.id ? [minimumSizeEdit] : [rules.required, rules.minimumSizePassword]"
                :hint="item.id ? '(Deixe em branco para não alterar)' : ''"
                :persistent-hint="!!item.id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="item.bloqueado"
                :label="'Bloqueado: ' + (item.bloqueado ? 'Sim' : 'Não')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-btn
                  v-if="!formDisable"
                  type="submit"
                  dark
                  block
                  color="green"
                  :loading="formLoading"
                  >Salvar</v-btn
                >
              </v-col>
              <v-col align="center" justify="center">
                <v-btn dark block color="red" @click="fecharForm">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import usuarioService from '@/services/usuarioService'
import FormPadrao from './FormPadrao'

export default {
  extends: FormPadrao,
  data() {
    return {
      service: usuarioService,
      show: false,
      minimumSizeEdit: (value) => {
        return (!value || value.length >= 5) || 'A senha deve conter no mínimo 5 caracteres.'
      },
    }
  },
  methods: {
    salvarUsuario() {
      if (this.item.id && this.item.password !== undefined && !this.item.password) {
        delete this.item.password
      }
      this.item.portal = true
      this.salvar();
    }
  }
}
</script>