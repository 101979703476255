<template>
  <v-skeleton-loader
    v-if="chamados.loading || preChamados.loading"
    type="table-heading, table-thead, image"
  ></v-skeleton-loader>
  <v-card elevation="1" v-else>
    <v-card-title style="font-weight: normal">
      Manutenções agendadas
      <v-spacer></v-spacer>
      {{title}}
      <v-btn
        icon
        @click="$refs.calendar.prev()"
        class="ml-2"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="[0, 1, 2, 3, 4, 5, 6]"
        type="month"
        :events="events"
        event-overlap-mode="stack"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
      ></v-calendar>

      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        max-width="400"
        max-height="600"
        offset-x
        offset-y
      >
        <v-card
          min-width="350px"
          flat
        >
          <v-list
            subheader
            two-line
            dense
            v-if="selectedEvent.list && selectedEvent.list.aprovados.length"
          >
            <v-subheader>
              {{selectedEvent.list.aprovados.length}}
              {{selectedEvent.list.aprovados.length == 1? 'Chamado' : 'Chamados'}}
              {{selectedEvent.list.aprovados.length == 1? 'aprovado' : 'aprovados'}}
            </v-subheader>

            <v-list-item v-for="aprovado in selectedEvent.list.aprovados" :key="aprovado.id">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{aprovado.descricao}}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{aprovado.entidade}}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-subtitle>{{aprovado.id}}</v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list
            subheader
            dense
            v-if="selectedEvent.list && selectedEvent.list.aguardando.length"
          >
            <v-subheader>
              {{selectedEvent.list.aguardando.length}}
              {{selectedEvent.list.aguardando.length == 1? 'Pré-chamado' : 'Pré-chamados'}}
              {{selectedEvent.list.aguardando.length == 1? 'pendente' : 'pendentes'}}
            </v-subheader>

            <v-list-item v-for="aguardando in selectedEvent.list.aguardando" :key="aguardando.id">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{aguardando.descricao}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
  import preChamadoService from '@/services/preChamadoService'
  import chamadoService from '@/services/chamadoService'
  import moment from 'moment'

  export default {
    name: 'Manutencao',
    data: () => ({
      idcliente: null,
      value: '',
      events: [],
      title: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      chamados: {
        lista: [],
        loading: null
      },
      preChamados: {
        lista: [],
        loading: null
      },
    }),
    mounted() {
      let usuario    = localStorage.getItem('usuario')
      this.idcliente = JSON.parse(usuario).idcliente || null

      this.events = []
      this.getChamados()
      this.getPreChamados()
    },
    methods: {
      getEvents () {
        this.title = this.$refs.calendar.title.replace(' ', ', ').replace(/^\w/, (c) => c.toUpperCase())
      },
      getChamados() {
        this.chamados.loading = true
        let parametros_chamados = {
          idcliente: this.idcliente,
          dinamico: true,
          colunas: [
            'chamados.id',
            'chamados.descricao',
            'chamados.data_prevista',
            'chamados.idativo',
            'chamados.idpraca',
            'chamados.idpista',
          ],
          with: [
            'praca:id,descricao',
            'ativo:id,descricao,idpraca',
            'ativo.praca:id,descricao',
            'pista:id,numero,sentido,idpraca',
            'pista.praca:id,descricao'
          ]
        }

        chamadoService.getAll(parametros_chamados).then((response) => {
          if (!response.erro) {
            this.chamados.lista = response.data
          }
        })
        .finally(() => {
          this.chamados.loading = false

          if (this.preChamados.loading === false) {
            this.processaChamados()
          }
        })
      },
      getPreChamados() {
        this.preChamados.loading = true
        let parametros_prechamados = {
          idcliente: this.idcliente,
          entidade: true
        }

        preChamadoService.getAll(parametros_prechamados).then((response) => {
          if (!response.erro) {
            this.preChamados.lista = response.data
          }
        })
        .finally(() => {
          this.preChamados.loading = false

          if (this.chamados.loading === false) {
            this.processaChamados()
          }
        })
      },
      processaChamados() {
        let processados = []
        this.events     = []
        
        this.preChamados.lista.forEach(i => {
          if (!i.data_prevista) {
            return;
          }

          i.data_prevista = moment(i.data_prevista).format('YYYY-MM-DD 00:00:00')

          let praca = i.praca
          
          let pracaProcessada = processados.find(j => j.id === praca.id && j.data_prevista === i.data_prevista)
          
          if (!pracaProcessada) {
            processados.push({
              id: praca.id,
              descricao: praca.descricao,
              data_prevista: i.data_prevista,
              aprovados: [],
              aguardando: []
            })

            pracaProcessada = processados.find(j => j.id === praca.id && j.data_prevista === i.data_prevista)
          }

          pracaProcessada.aguardando.push({
            id: i.id,
            descricao: i.descricao
          })
        })

        this.chamados.lista.forEach(i => {
          if (!i.data_prevista) {
            return;
          }

          i.data_prevista = moment(i.data_prevista).format('YYYY-MM-DD 00:00:00')

          let praca = i.praca || (i.ativo ? i.ativo.praca : i.pista.praca)
          let entidade = i.pista || i.ativo || i.praca

          let pracaProcessada = processados.find(j => j.id === praca.id && j.data_prevista === i.data_prevista)
          
          if (!pracaProcessada) {
            processados.push({
              id: praca.id,
              descricao: praca.descricao,
              data_prevista: i.data_prevista,
              aprovados: [],
              aguardando: []
            })

            pracaProcessada = processados.find(j => j.id === praca.id && j.data_prevista === i.data_prevista)
          }

          let dsc_entidade = ''

          if (entidade.numero) {
            dsc_entidade = `Pista ${entidade.numero} - ${entidade.sentido.toUpperCase()}`
          } else if (entidade.equipamento) {
            dsc_entidade = entidade.equipamento.descricao + ' (' + entidade.tag_empresa + ')'
          } else {
            dsc_entidade = entidade.descricao
          }
          
          pracaProcessada.aprovados.push({
            id: i.id,
            descricao: i.descricao,
            entidade: dsc_entidade
          })
        })
        
        processados.forEach((val) => {
          //jogando na variavel que lista no calendario
          this.events.push({
            list: {
              aprovados: val.aprovados || [],
              aguardando: val.aguardando || []
            },
            name: val.descricao.replace(' pedágio -', ''),
            start: new Date(val.data_prevista),
            color: `hsl(${val.id*20}, 48%, 48%)`,
            timed: false,
          })
        })

      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
  }
</script>