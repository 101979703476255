import crudService from "@/services/crudService";

class pistaService extends crudService {

  constructor() {
    super();
    this.endpoint = '/pista';
  }
}

export default new pistaService;