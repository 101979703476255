<template>
  <v-container>
    <v-skeleton-loader
      v-if="loading"
      type="table"
    ></v-skeleton-loader>

    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="lista"
      :search="search"
      sort-by="nome"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 5, 15]
      }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Plantão</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            class="mt-5"
            v-model="plantao_atual"
            label="Plantão atual"
          ></v-switch>
        </v-toolbar>
        
      </template>
    </v-data-table>
    <v-card
    class="mx-auto mt-4"
    width="800px"
    >
    <v-card-title>
      <v-col justify="center" align="center">
        <h2>Informações do Plantão</h2>
      </v-col>
    </v-card-title>
    <v-card-text class="text-h5 font-weight-bold">
      <v-row>
        <v-col cols="12" justify="center" align="center">
          <v-chip color="white" text-color="black" class="ma-1">Plantão das 18 horas às 8 horas nos dias uteis</v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-chip color="white" text-color="black" class="ma-1">Os chamados de plantão devem ser comunicados via e-mail para o endereço abaixo:</v-chip>
          <v-chip color="white" text-color="black" class="ma-1">ezequiel.scharurich@cwfirpo.com.br</v-chip>
        </v-col>
        <v-col cols="12">
          <v-chip color="white" text-color="black" class="mb-1">Caso não consigo realizar contato com o técnico responsável, entrar em contato com:</v-chip>
          <v-chip color="white" text-color="black" class="ma-1">Ezequeiel Schaurich - Supervisor</v-chip>
          <v-chip color="white" text-color="black" class="ma-1">Telefone: (51) 9 98233401</v-chip>
        </v-col>
        <v-col  cols="12">
          <v-chip color="white" text-color="black" class="ma-1">Thomas Muller - PCM</v-chip>
          <v-chip color="white" text-color="black" class="ma-1">Telefone: (51) 9 97816526</v-chip>
        </v-col>
        <v-col cols="12">
          <v-chip color="white" text-color="black" class="ma-1">Caio Saibel - Diretor de contratos </v-chip>
          <v-chip color="white" text-color="black" class="ma-1">Telefone: (51) 9 96847664</v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
  import plantaoService from '@/services/plantaoService'
  import moment from 'moment'

  export default {
    watch: {
      plantao_atual() {
        this.getPlantao()
      }
    },
    data: () => ({
      idcliente: null,
      headers: [
        { text: "Plantão", value: "id" },
        { text: "Solicitante", value: "solicitante" },
        { text: "Técnico", value: "tecnico" },
        { text: "Praça", value: "praca" },
        { text: "Data inicial", value: "dataini" },
        { text: "Data final", value: "datafim" },
        { text: "telefone", value: "telefone" },
      ],
      search: '',
      loading: false,
      lista: [],
      itemsPerPage: [50, 5, 10],
      plantao_atual: true
    }),
    mounted () {
      let usuario    = localStorage.getItem('usuario')
      this.idcliente = JSON.parse(usuario).idcliente || null

      this.getPlantao()
    },
    methods: {
      getPlantao() {
        this.loading = true
        let filtros = {
          idcliente: this.idcliente
        }

        if (this.plantao_atual) {
          filtros.dataini = this.selectWeek()
        }

        this.lista = []
        plantaoService.getAll(filtros).then((response) => {
          response.data.forEach(plantao => {
            this.lista.push({
              id: plantao.id,
              praca: plantao.dados.praca,
              tecnico: plantao.dados.tecnico,
              telefone: plantao.dados.telefone,
              solicitante: plantao.solicitante.nome,
              dataini: moment(plantao.dados.dataini).format('DD/MM/YYYY [08:00]'),
              datafim: moment(plantao.dados.datafim).format('DD/MM/YYYY [18:00]')
            })
          })
        }).finally(() => {
          this.loading = false;
        })
      },
      selectWeek() {
        let selectedDate = moment().format('YYYY-MM-DD [00:00]')
        let date = new Date(selectedDate)
        let atual
        let semana = date.getDay()

        if (semana === 0) {
          atual = moment(selectedDate).subtract(6, 'days').format('YYYY-MM-DD')
        } else {
          atual = moment(selectedDate).subtract(semana - 1, 'days').format('YYYY-MM-DD')
        }

        return atual
      }
    },
  }
</script>