var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pracas,"search":_vm.search,"loading":_vm.openingForm,"sort-by":"descricao","expanded":_vm.expanded,"item-key":"idpraca","single-expand":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Chamados")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Busca","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" Período "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-calendar-search ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dates = []}}},[_vm._v(" Limpar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.filtrarDatas(_vm.dates)}}},[_vm._v(" Filtrar ")])],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticStyle:{"border-radius":"0","background":"#F9F9F9"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.itens),function(pista,i){return _c('v-hover',{key:("" + (item.idpraca) + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openForm(_vm.formChamado, pista)}}},[_c('td',{staticClass:"text-start",staticStyle:{"width":"50%","padding-left":"30px"}},[_vm._v(_vm._s(pista.descricao))]),_c('td',{staticClass:"text-end",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(pista.melhorias))]),_c('td',{staticClass:"text-end",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(pista.corretivas))]),_c('td',{staticClass:"text-end",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(pista.preventivas))]),_c('td',{staticClass:"text-start",staticStyle:{"width":"5%"}},[(hover)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-timeline-text ")])]}}],null,true)},[_c('span',[_vm._v("Ver chamados")])]):_vm._e()],1)])]}}],null,true)})}),1)]},proxy:true}],null,true)})],1)]}}])}),_c('v-overlay',{attrs:{"value":_vm.openingForm}}),_c('form-timeline-chamado',{attrs:{"formWidth":"900px","showDialog":_vm.formChamado.show,"formItem":_vm.formChamado.item},on:{"formClose":function($event){return _vm.closeForm(_vm.formChamado)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }