var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loadingUsuarios)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.loadingUsuarios)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.usuarios,"search":_vm.search,"sort-by":"nome"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Usuários")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Busca","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openForm(_vm.formUsuario)}}},[_vm._v(" Novo Usuário ")])],1)]},proxy:true},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openForm(_vm.formUsuario, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openFormPermissoes(_vm.formPermissoes, item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-shield ")])]}}],null,true)},[_c('span',[_vm._v("Permissões")])])]}}],null,true)}):_vm._e(),_c('form-usuario',{attrs:{"labelName":"Usuário","formWidth":"650px","showDialog":_vm.formUsuario.show,"formItem":_vm.formUsuario.item},on:{"formClose":function($event){return _vm.closeForm(_vm.formUsuario)},"objetoSalvo":function($event){return _vm.salvo()}}}),_c('form-permissoes',{attrs:{"labelName":"Permissões","formWidth":"650px","showDialog":_vm.formPermissoes.show,"formItem":_vm.formPermissoes.item},on:{"formClose":function($event){return _vm.closeForm(_vm.formPermissoes)},"objetoSalvo":function($event){return _vm.getUsuarios()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }