<template>
  <v-card class="mx-2">
    <v-card-title>Chamados: {{total}}</v-card-title>
    <v-card-text class="px-4">
      <canvas id="chartChamados"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
  import relatorioService from '@/services/relatorioService'
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    watch: {
      filtrosStore() {
        this.getData()
      }
    },
    data () {
      return {
        chart: null,
        loaded: false,
        total: '...',
        datacollection: {
          labels: ['Corretiva', 'Preventiva', 'Melhoria','Instalação', 'Aguardando', 'Executando', 'Encerrado'],
          datasets: [
            {
              label: 'Tipo',
              data: [],
              backgroundColor: ['#EF5350', '#42A5F5', '#66BB6A','#78909C', '#FFEE58', '#D4E157', '#BDBDBD'],
            },
            {
              label: 'Situação',
              data: [],
              backgroundColor: ['#EF5350', '#42A5F5', '#66BB6A', '#78909C', '#FFEE58', '#D4E157', '#BDBDBD'],
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: false,
            }
          }
        }
      }
    },
    computed: {
      filtrosStore: {
        get(){
          return this.$store.state.sgmDashboardFiltros
        },
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.loaded = false

        if (this.chart) {
          this.chart.destroy()
        }

        relatorioService.chamados(this.filtrosStore).then((response) => {
          if (!response.erro) {
            /*response.data.map(i => {
              this.datacollection.labels.push(i.descricao)
              this.datacollection.datasets[0].data.push(i.quantidade / 3600)

              let cor = this.colors[i.id % this.colors.length]
              let variacao = 'lighten' + (Math.ceil(i.id / this.colors.length))

              this.datacollection.datasets[0].backgroundColor.push(colors[cor][variacao])
            })*/

            this.total = response.data.corretiva + response.data.preventiva + response.data.melhoria + response.data.instalacao

            this.datacollection.datasets[0].data = [
              response.data.corretiva,
              response.data.preventiva,
              response.data.melhoria,
              response.data.instalacao
            ].concat([0, 0, 0])
            
            this.datacollection.datasets[1].data = [0, 0, 0, 0].concat([
              response.data.aguardando,
              response.data.executando,
              response.data.encerrado,
            ])

            this.chart = new Chart(
              document.getElementById('chartChamados'),
              {
                type: 'pie',
                data: this.datacollection,
                options: this.options
              }
            )

            this.loaded = true
          }
        })
      }
    }
  }
</script>