import crudService from "@/services/crudService";
import http from '@/plugins/axios';

class usuarioService extends crudService {

  constructor() {
    super();
    this.endpoint = '/usuario';
  }

  alterarSenha(dados) {
    return http.post(this.endpoint + '/trocarsenha', dados)
  }
}

export default new usuarioService;